define("ember-svg-jar/inlined/upload-cloud-plain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_23255_55364)\" stroke=\"#fff\" stroke-width=\"1.4\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M12.5 12.25l-3-3-3 3M9.5 9.25V16\"/><path d=\"M15.793 14.043A3.75 3.75 0 0014 7h-.945A6 6 0 102.75 12.475\"/></g><defs><clipPath id=\"clip0_23255_55364\"><path fill=\"#fff\" transform=\"translate(.5 .25)\" d=\"M0 0h18v18H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "19",
      "height": "19",
      "viewBox": "0 0 19 19",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});