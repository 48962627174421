define("ember-svg-jar/inlined/maximize-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.5 17.5h-5v-5\" stroke=\"currentColor\" stroke-width=\"1.4\" stroke-linecap=\"square\" stroke-linejoin=\"round\"/><path d=\"M7.5 17.5h-5v-5\" stroke=\"currentColor\" stroke-opacity=\".2\" stroke-width=\"1.4\" stroke-linecap=\"square\" stroke-linejoin=\"round\"/><path d=\"M7.5 17.5h-5v-5\" stroke=\"currentColor\" stroke-opacity=\".2\" stroke-width=\"1.4\" stroke-linecap=\"square\" stroke-linejoin=\"round\"/><path d=\"M2.005 17.004a.7.7 0 10.99.99l-.99-.99zm6.823-4.843l.495-.495-.99-.99-.495.495.99.99zm-5.833 5.833l5.833-5.833-.99-.99-5.833 5.833.99.99z\" fill=\"currentColor\"/><path d=\"M2.005 17.004a.7.7 0 10.99.99l-.99-.99zm6.823-4.843l.495-.495-.99-.99-.495.495.99.99zm-5.833 5.833l5.833-5.833-.99-.99-5.833 5.833.99.99z\" fill=\"currentColor\" fill-opacity=\".2\"/><path d=\"M2.005 17.004a.7.7 0 10.99.99l-.99-.99zm6.823-4.843l.495-.495-.99-.99-.495.495.99.99zm-5.833 5.833l5.833-5.833-.99-.99-5.833 5.833.99.99z\" fill=\"currentColor\" fill-opacity=\".2\"/><path d=\"M12.5 2.5h5v5\" stroke=\"currentColor\" stroke-width=\"1.4\" stroke-linecap=\"square\" stroke-linejoin=\"round\"/><path d=\"M12.5 2.5h5v5\" stroke=\"currentColor\" stroke-opacity=\".2\" stroke-width=\"1.4\" stroke-linecap=\"square\" stroke-linejoin=\"round\"/><path d=\"M12.5 2.5h5v5\" stroke=\"currentColor\" stroke-opacity=\".2\" stroke-width=\"1.4\" stroke-linecap=\"square\" stroke-linejoin=\"round\"/><path d=\"M17.995 2.995a.7.7 0 10-.99-.99l.99.99zm-6.823 4.843l-.495.495.99.99.495-.495-.99-.99zm5.833-5.833l-5.833 5.833.99.99 5.833-5.833-.99-.99z\" fill=\"currentColor\"/><path d=\"M17.995 2.995a.7.7 0 10-.99-.99l.99.99zm-6.823 4.843l-.495.495.99.99.495-.495-.99-.99zm5.833-5.833l-5.833 5.833.99.99 5.833-5.833-.99-.99z\" fill=\"currentColor\" fill-opacity=\".2\"/><path d=\"M17.995 2.995a.7.7 0 10-.99-.99l.99.99zm-6.823 4.843l-.495.495.99.99.495-.495-.99-.99zm5.833-5.833l-5.833 5.833.99.99 5.833-5.833-.99-.99z\" fill=\"currentColor\" fill-opacity=\".2\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});