define("ember-remodal/services/remodal", ["exports", "@ember/debug", "@ember/object/computed", "@ember/service"], function (_exports, _debug, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _service.default.extend({
    modal: null,
    title: (0, _computed.alias)('ember-remodal.title'),
    text: (0, _computed.alias)('ember-remodal.text'),
    confirmButton: (0, _computed.alias)('ember-remodal.confirmButton'),
    cancelButton: (0, _computed.alias)('ember-remodal.cancelButton'),
    disableNativeClose: (0, _computed.alias)('ember-remodal.disableNativeClose'),
    disableForeground: (0, _computed.alias)('ember-remodal.disableForeground'),
    disableAnimation: (0, _computed.alias)('ember-remodal.disableAnimation'),
    buttonClasses: (0, _computed.alias)('ember-remodal.buttonClasses'),
    modifier: (0, _computed.alias)('ember-remodal.modifier'),
    closeOnEscape: (0, _computed.alias)('ember-remodal.closeOnEscape'),
    closeOnCancel: (0, _computed.alias)('ember-remodal.closeOnCancel'),
    closeOnConfirm: (0, _computed.alias)('ember-remodal.closeOnConfirm'),
    hashTracking: (0, _computed.alias)('ember-remodal.hashTracking'),
    closeOnOutsideClick: (0, _computed.alias)('ember-remodal.closeOnOutsideClick'),
    open: function open() {
      var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'ember-remodal';
      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var modal = this.get(name);
      if (modal) {
        if (opts) {
          modal.setProperties(opts);
        }
        return modal.open();
      } else {
        this._modalNotSetError(name);
      }
    },
    close: function close() {
      var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'ember-remodal';
      var modal = this.get(name);
      if (modal) {
        return modal.close();
      } else {
        this._modalNotSetError(name);
      }
    },
    _modalNotSetError: function _modalNotSetError(name) {
      (true && !(false) && (0, _debug.assert)("The requested modal, \"".concat(name, "\" can not be opened because it is not rendered in the current route. In order to use ember-remodal as a service, an instance of {{ember-remodal}} must currently be rendered, with \"forService=true\". Try putting it in your application template.")));
    }
  });
});