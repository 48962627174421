define("ember-svg-jar/inlined/filter-chevron-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M0 6l6-6 6 6\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "12",
      "height": "6",
      "viewBox": "0 0 6 12",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});