define("ember-svg-jar/inlined/duplicate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15 6.75H8.25a1.5 1.5 0 00-1.5 1.5V15a1.5 1.5 0 001.5 1.5H15a1.5 1.5 0 001.5-1.5V8.25a1.5 1.5 0 00-1.5-1.5z\" stroke=\"#0050C2\" stroke-width=\"1.6\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M3.75 11.25H3a1.5 1.5 0 01-1.5-1.5V3A1.5 1.5 0 013 1.5h6.75a1.5 1.5 0 011.5 1.5v.75\" stroke=\"#0050C2\" stroke-width=\"1.6\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});