define("ember-svg-jar/inlined/search-clouds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M29.038 3c-1.408 0-2.576.808-3.288 1.934-.135-.02-.26-.059-.404-.059-1.54 0-2.77 1.084-3.173 2.52a2.79 2.79 0 00-2.673 2.793c0 1.545 1.248 2.812 2.77 2.812h9.845c1.863 0 3.385-1.545 3.385-3.438 0-1.608-1.12-2.915-2.596-3.28C32.536 4.422 30.97 3 29.038 3zm0 1.25c1.46 0 2.635 1.13 2.75 2.578l.058.527.5.04a2.162 2.162 0 011.923 2.168c0 1.215-.956 2.187-2.154 2.187H22.27a1.543 1.543 0 01-1.538-1.563c0-.869.682-1.562 1.538-1.562.056 0 .123.002.231.02l.654.117.058-.664c.108-1.111 1.012-1.973 2.134-1.973.161 0 .327.017.5.059l.48.117.212-.45a2.76 2.76 0 012.5-1.601z\" fill=\"#1B79FF\"/><circle cx=\"14.2\" cy=\"13.7\" r=\".7\" fill=\"#D9D9D9\"/><path d=\"M16.333 22.167a9.333 9.333 0 100-18.667 9.333 9.333 0 000 18.667z\" fill=\"#fff\" stroke=\"url(#paint0_linear_16572_86084)\" stroke-width=\"1.4\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M28 24.5l-5.075-5.075\" stroke=\"#1B79FF\" stroke-width=\"1.4\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M4 19.166h.525a3.333 3.333 0 113.225 4.167H4a2.083 2.083 0 010-4.166z\" fill=\"#fff\" stroke=\"#4E52B7\" stroke-width=\"1.4\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><circle cx=\"16.6\" cy=\"12.7\" r=\".7\" fill=\"#1B79FF\"/><circle cx=\"20.001\" cy=\"12.7\" r=\".7\" fill=\"#1B79FF\"/><circle cx=\"13.2\" cy=\"12.7\" r=\".7\" fill=\"#1B79FF\"/><defs><linearGradient id=\"paint0_linear_16572_86084\" x1=\"9.074\" y1=\"22.167\" x2=\"25.667\" y2=\"22.167\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#4E52B7\"/><stop offset=\"1\" stop-color=\"#2F85FF\"/></linearGradient></defs>",
    "attrs": {
      "width": "45",
      "height": "36",
      "viewBox": "0 0 45 36",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});