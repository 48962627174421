define("ember-data-model-fragments/index", ["exports", "ember", "ember-data-model-fragments/version", "ember-data-model-fragments/fragment", "ember-data-model-fragments/array/fragment", "ember-data-model-fragments/transforms/fragment", "ember-data-model-fragments/transforms/fragment-array", "ember-data-model-fragments/transforms/array", "ember-data-model-fragments/attributes"], function (_exports, _ember, _version, _fragment, _fragment2, _fragment3, _fragmentArray, _array, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
    Ember Data Model Fragments
  
    @module ember-data-model-fragments
    @main ember-data-model-fragments
  */
  var MF = _ember.default.Namespace.create({
    // eslint-disable-line ember/new-module-imports
    VERSION: _version.default,
    Fragment: _fragment.default,
    FragmentArray: _fragment2.default,
    FragmentTransform: _fragment3.default,
    FragmentArrayTransform: _fragmentArray.default,
    ArrayTransform: _array.default,
    fragment: _attributes.fragment,
    fragmentArray: _attributes.fragmentArray,
    array: _attributes.array,
    fragmentOwner: _attributes.fragmentOwner
  });
  if (_ember.default.libraries) {
    _ember.default.libraries.register('Model Fragments', MF.VERSION);
  }
  var _default = _exports.default = MF;
});