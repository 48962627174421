define("ember-svg-jar/inlined/checkbox-checked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#F8F8F9\" d=\"M1.528 30.916c0 3.117 2.553 5.668 5.67 5.668h21.938c3.117 0 5.67-2.551 5.67-5.668V8.977c0-3.118-2.553-5.669-5.67-5.669H7.2c-3.119 0-5.67 2.551-5.67 5.669l-.002 21.939z\"/><path fill=\"none\" stroke=\"#CFD1D2\" stroke-width=\"3\" stroke-miterlimit=\"10\" d=\"M1.528 30.916c0 3.117 2.553 5.668 5.67 5.668h21.938c3.117 0 5.67-2.551 5.67-5.668V8.977c0-3.118-2.553-5.669-5.67-5.669H7.2c-3.119 0-5.67 2.551-5.67 5.669l-.002 21.939h0z\"/><path fill=\"#F8F8F9\" d=\"M1.528 29.384c0 3.117 2.553 5.668 5.67 5.668h21.938c3.117 0 5.67-2.551 5.67-5.668V7.448c0-3.118-2.553-5.669-5.67-5.669H7.2c-3.119 0-5.67 2.551-5.67 5.669l-.002 21.936z\"/><path fill=\"none\" stroke=\"#B4B4B5\" stroke-width=\"3\" stroke-miterlimit=\"10\" d=\"M1.528 29.384c0 3.117 2.553 5.668 5.67 5.668h21.938c3.117 0 5.67-2.551 5.67-5.668V7.448c0-3.118-2.553-5.669-5.67-5.669H7.2c-3.119 0-5.67 2.551-5.67 5.669l-.002 21.936h0z\"/><path fill=\"#4D81BE\" d=\"M4.641 20.878l3.521-5.915 7.122 7.485 15.695-16.99-.078 7.805-16.082 17.906z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "36.333",
      "height": "39.167",
      "viewBox": "0 0 36.333 39.167"
    }
  };
});