define("ember-svg-jar/inlined/filter-chevron-up-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M.148 6.144a.5.5 0 000 .71l2.497 2.498a.5.5 0 00.71 0l2.497-2.498a.5.5 0 00-.162-.82.5.5 0 00-.547.11L3 8.293.857 6.144a.5.5 0 00-.71 0zM2.645.648L.148 3.146a.502.502 0 00.71.71L3 1.707l2.143 2.149a.5.5 0 00.819-.163.5.5 0 00-.11-.547L3.355.648a.5.5 0 00-.71 0z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "6",
      "height": "10",
      "viewBox": "0 0 6 10",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});