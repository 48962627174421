define("ember-svg-jar/inlined/download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.834 2.5a.833.833 0 00-1.667 0v7.989L6.423 7.744a.833.833 0 00-1.179 1.179l4.167 4.167a.833.833 0 001.179 0l4.166-4.167a.833.833 0 10-1.178-1.179l-2.744 2.745V2.5z\" fill=\"#fff\"/><path d=\"M2.5 11.667c.46 0 .834.373.834.833v3.334a.833.833 0 00.833.833h11.667a.833.833 0 00.833-.833V12.5a.833.833 0 011.667 0v3.334a2.5 2.5 0 01-2.5 2.5H4.167a2.5 2.5 0 01-2.5-2.5V12.5c0-.46.373-.833.833-.833z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});