define("ember-paper/components/paper-tooltip-inner", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "ember-paper/templates/components/paper-tooltip-inner", "ember-css-transitions/utils/transition-utils", "ember-paper/utils/calculate-tooltip-position"], function (_exports, _component, _object, _runloop, _paperTooltipInner, _transitionUtils, _calculateTooltipPosition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  var _default = _exports.default = _component.default.extend({
    layout: _paperTooltipInner.default,
    tagName: '',
    positionClass: (0, _object.computed)('position', function () {
      return "md-origin-".concat(this.position);
    }),
    setupTooltip: function setupTooltip(element, _ref) {
      var _ref2 = _slicedToArray(_ref, 2),
        position = _ref2[0],
        anchorElement = _ref2[1];
      _runloop.run.schedule('afterRender', function () {
        var pos = (0, _calculateTooltipPosition.default)(element, anchorElement, position);
        element.style.top = "".concat(pos.top, "px");
        element.style.left = "".concat(pos.left, "px");
        element.classList.add('md-show-add');
        (0, _transitionUtils.nextTick)().then(_transitionUtils.nextTick).then(_transitionUtils.nextTick).then(_transitionUtils.nextTick).then(function () {
          element.classList.add('md-show');
        });
      });
    }
  });
});