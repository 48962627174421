define("ember-svg-jar/inlined/aurora-navbar-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M0 0s9.662 4.968 28.04 6.454L34 16.252l-.607.716-6.512-6.826S1.044 7.583 0 0z\" fill=\"url(#paint0_linear_15142_24994)\"/><path d=\"M1.729 6.84s8.485 4.54 24.379 5.655l5.298 6.812-.538.62-5.712-4.377S2.308 13.61 1.729 6.84z\" fill=\"url(#paint1_linear_15142_24994)\"/><path d=\"M3.302 13.115s6.663 3.798 21.15 4.623l4.43 4.418-.415.495-4.677-2.683s-19.454-.743-20.488-6.853z\" fill=\"url(#paint2_linear_15142_24994)\"/><defs><linearGradient id=\"paint0_linear_15142_24994\" x1=\"3.778\" y1=\"22.651\" x2=\"34\" y2=\"22.651\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#4E52B7\"/><stop offset=\"1\" stop-color=\"#2F85FF\"/></linearGradient><linearGradient id=\"paint1_linear_15142_24994\" x1=\"3.778\" y1=\"22.651\" x2=\"34\" y2=\"22.651\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#4E52B7\"/><stop offset=\"1\" stop-color=\"#2F85FF\"/></linearGradient><linearGradient id=\"paint2_linear_15142_24994\" x1=\"3.778\" y1=\"22.651\" x2=\"34\" y2=\"22.651\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#4E52B7\"/><stop offset=\"1\" stop-color=\"#2F85FF\"/></linearGradient></defs>",
    "attrs": {
      "width": "34",
      "height": "23",
      "viewBox": "0 0 34 23",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});