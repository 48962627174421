define("ember-paper/components/paper-tab", ["exports", "@ember/object", "@ember/component", "@ember/string", "ember-paper/templates/components/paper-tab", "ember-composability-tools", "ember-paper/mixins/focusable-mixin", "ember-paper/utils/invoke-action"], function (_exports, _object, _component, _string, _paperTab, _emberComposabilityTools, _focusableMixin, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_emberComposabilityTools.ChildMixin, _focusableMixin.default, {
    layout: _paperTab.default,
    tagName: 'md-tab-item',
    classNames: ['md-tab'],
    classNameBindings: ['isSelected:md-active'],
    attributeBindings: ['isSelected:aria-selected', 'style', 'maybeHref:href'],
    // <a> tags have browser styles or are usually styled by the user
    // this makes sure that tab item still looks good with an anchor tag
    style: (0, _object.computed)('href', function () {
      if (this.href) {
        return (0, _string.htmlSafe)('text-decoration: none; border: none;');
      } else {
        return undefined;
      }
    }),
    maybeHref: (0, _object.computed)('href', 'disabled', function () {
      if (this.href && !this.disabled) {
        return this.href;
      } else {
        return undefined;
      }
    }),
    isSelected: (0, _object.computed)('selected', 'value', function () {
      return this.selected === this.value;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      if (this.href) {
        this.set('tagName', 'a');
      }
    },
    // this method is called by the parent
    updateDimensions: function updateDimensions() {
      // this is the true current width
      // it is used to calculate the ink bar position & pagination offset
      this.setProperties({
        left: this.element.offsetLeft,
        width: this.element.offsetWidth
      });
    },
    click: function click() {
      if (!this.disabled) {
        _invokeAction.invokeAction.apply(void 0, [this, 'onClick'].concat(Array.prototype.slice.call(arguments)));
        (0, _invokeAction.invokeAction)(this, 'onSelect', this);
      }
    }
  });
});