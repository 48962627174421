define("ember-svg-jar/inlined/error-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_1933_5476)\" stroke=\"#DE1135\" stroke-width=\"1.4\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 000 16.666z\" fill=\"#FFF1F4\"/><path d=\"M12.5 7.5l-5 5M7.5 7.5l5 5\"/></g><defs><clipPath id=\"clip0_1933_5476\"><path fill=\"#fff\" d=\"M0 0h20v20H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});