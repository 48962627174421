define("ember-svg-jar/inlined/edit-bom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10 16.666h7.5M13.75 2.917a1.768 1.768 0 012.5 2.5L5.833 15.833l-3.333.834.833-3.334L13.75 2.917z\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none"
    }
  };
});