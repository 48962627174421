define("ember-rollbar-client/instance-initializers/rollbar", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(appInstance) {
    var fastbootService = appInstance.lookup('service:fastboot');
    var rollbarService = appInstance.lookup('service:rollbar');
    var oldOnError = _ember.default.onerror || function () {};
    _ember.default.onerror = function () {
      oldOnError.apply(void 0, arguments);
      var enabled = rollbarService.get('enabled');
      if (enabled) {
        rollbarService.error.apply(rollbarService, arguments);
      }
      if (!enabled || _ember.default.testing) {
        if (!fastbootService || !fastbootService.get('isFastBoot')) {
          throw arguments.length <= 0 ? undefined : arguments[0];
        }
      }
    };
  }
  var _default = _exports.default = {
    initialize: initialize
  };
});