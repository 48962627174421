define("ember-svg-jar/inlined/trash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_3136_31293)\" fill=\"currentColor\"><path d=\"M12.667 1.885v.99h4.156a.594.594 0 010 1.188H2.177a.594.594 0 010-1.188h4.156v-.99C6.333 1.121 6.953.5 7.72.5h3.562c.765 0 1.386.62 1.386 1.385zm-5.146 0v.99h3.958v-.99a.198.198 0 00-.198-.198H7.72a.198.198 0 00-.198.198zM3.956 5.391a.594.594 0 10-1.182.114l1.118 11.557a1.386 1.386 0 001.379 1.25h8.458a1.385 1.385 0 001.38-1.251l1.118-11.556a.594.594 0 00-1.183-.114l-1.118 11.555a.197.197 0 01-.197.179H5.271a.198.198 0 01-.197-.179L3.956 5.391z\"/><path d=\"M7.288 6.438a.594.594 0 01.628.558l.396 6.73a.594.594 0 11-1.187.069l-.396-6.73a.594.594 0 01.558-.627h.001zm4.982.628a.594.594 0 10-1.187-.07l-.396 6.73a.594.594 0 001.186.069l.397-6.73z\"/></g><defs><clipPath id=\"clip0_3136_31293\"><path fill=\"#fff\" transform=\"translate(0 .5)\" d=\"M0 0h19v19H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "19",
      "height": "20",
      "viewBox": "0 0 19 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});