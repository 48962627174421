define("ember-svg-jar/inlined/data-imported-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.188 4.875H22.75V6.5H12.187V4.875zm0 14.625H22.75v1.625H12.187V19.5zM3.25 12.187h10.563v1.626H3.25v-1.626zm2.438-3.25a3.25 3.25 0 110-6.5 3.25 3.25 0 010 6.5zm0-4.874a1.625 1.625 0 100 3.25 1.625 1.625 0 000-3.25zm0 19.5a3.25 3.25 0 110-6.5 3.25 3.25 0 010 6.5zm0-4.875a1.625 1.625 0 100 3.249 1.625 1.625 0 000-3.25zm14.625-2.438a3.25 3.25 0 110-6.5 3.25 3.25 0 010 6.5zm0-4.875a1.625 1.625 0 100 3.25 1.625 1.625 0 000-3.25z\" fill=\"url(#paint0_linear_7267_39683)\"/><defs><linearGradient id=\"paint0_linear_7267_39683\" x1=\"4.785\" y1=\"23.563\" x2=\"23.563\" y2=\"23.563\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#4E52B7\"/><stop offset=\"1\" stop-color=\"#2F85FF\"/></linearGradient></defs>",
    "attrs": {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});