define("ember-svg-jar/inlined/edit-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_10800_115994)\"><path d=\"M14.166 2.5a2.357 2.357 0 013.333 3.333L6.25 17.083l-4.583 1.25 1.25-4.583L14.166 2.5z\" stroke=\"#FAFAFA\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></g><defs><clipPath id=\"clip0_10800_115994\"><path fill=\"#fff\" d=\"M0 0h20v20H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});