define("ember-svg-jar/inlined/barcode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_25891_90218)\"><path d=\"M18.741 20c-.344 0-.64-.12-.887-.362a1.194 1.194 0 01-.371-.89V1.252c0-.352.123-.648.37-.89.248-.24.544-.361.888-.361.345 0 .638.12.878.362.241.24.361.537.361.89v17.497c0 .352-.12.648-.36.89-.241.24-.534.361-.879.361zm-3.131 0a.6.6 0 01-.44-.186.602.602 0 01-.185-.44V.626c0-.183.062-.333.186-.45A.616.616 0 0115.61 0a.616.616 0 01.624.626v18.748c0 .17-.062.317-.185.44a.6.6 0 01-.44.186zm-3.112 0c-.345 0-.641-.12-.888-.362a1.194 1.194 0 01-.371-.89V1.252c0-.352.12-.648.361-.89.24-.24.537-.361.888-.361s.647.12.888.362c.24.24.36.537.36.89v17.497c0 .352-.12.648-.36.89-.241.24-.534.361-.878.361zm-4.38 0a.6.6 0 01-.44-.186.602.602 0 01-.185-.44V.626A.616.616 0 018.117 0a.616.616 0 01.624.626v18.748c0 .17-.061.317-.185.44a.6.6 0 01-.439.186zm-3.113 0c-.345 0-.64-.12-.888-.362a1.194 1.194 0 01-.37-.89V1.252c0-.352.123-.648.37-.89.247-.24.54-.361.878-.361.338 0 .631.12.878.362.247.24.37.537.37.89v17.497c0 .352-.12.648-.36.89-.24.24-.533.361-.878.361zm-3.746 0c-.345 0-.641-.12-.888-.362a1.194 1.194 0 01-.371-.89V1.252C0 .9.12.603.361.361.601.122.898 0 1.249 0s.647.12.888.362c.24.24.36.537.36.89v17.497c0 .352-.12.648-.36.89-.241.24-.534.361-.878.361z\" fill=\"#4A5468\"/></g><defs><clipPath id=\"clip0_25891_90218\"><path fill=\"#fff\" d=\"M0 0h20v20H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});