define("ember-svg-jar/inlined/cog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.101 0L6.78 1.657a7.97 7.97 0 00-3.237 1.808l-1.644-.557L0 6.092l1.312 1.09c-.15.588-.28 1.185-.28 1.818s.13 1.23.28 1.818L0 11.908l1.899 3.184 1.639-.562a7.986 7.986 0 003.242 1.818L7.1 18H10.9l.321-1.652a7.99 7.99 0 003.237-1.818l1.64.562L18 11.908l-1.318-1.09c.15-.588.286-1.185.286-1.818s-.13-1.23-.28-1.818L18 6.092l-1.899-3.184-1.639.557a7.965 7.965 0 00-3.242-1.808L10.9 0H7.1zm1.1 1.286H9.8l.26 1.356.425.1c1.317.291 2.48.96 3.371 1.884l.296.306 1.349-.457.799 1.336-1.08.899.13.402a6.293 6.293 0 010 3.776l-.13.402 1.08.9-.8 1.335-1.348-.462-.296.311a6.729 6.729 0 01-3.371 1.889l-.426.09-.26 1.361H8.202l-.26-1.36-.425-.091a6.73 6.73 0 01-3.371-1.889l-.301-.31-1.349.461-.794-1.336 1.08-.899-.13-.402a6.291 6.291 0 010-3.776l.13-.402-1.08-.9.794-1.335 1.354.457.296-.306a6.684 6.684 0 013.371-1.884l.426-.1.26-1.356zM9 5.143C6.806 5.143 5.016 6.876 5.016 9S6.806 12.857 9 12.857 12.984 11.124 12.984 9 11.194 5.143 9 5.143zm0 1.286c1.473 0 2.656 1.145 2.656 2.571 0 1.426-1.183 2.571-2.656 2.571S6.344 10.426 6.344 9c0-1.426 1.183-2.571 2.656-2.571z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});