define("ember-svg-jar/inlined/x-octagon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.67 2.333h9.66l6.836 6.837v9.66l-6.836 6.836H9.67L2.833 18.83V9.17L9.67 2.333z\" fill=\"#fff\"/><path d=\"M9.67 2.333h9.66l6.836 6.837v9.66l-6.836 6.836H9.67L2.833 18.83V9.17L9.67 2.333z\" fill=\"url(#paint0_linear_23750_95746)\" fill-opacity=\".3\"/><path d=\"M9.67 2.333h9.66l6.836 6.837v9.66l-6.836 6.836H9.67L2.833 18.83V9.17L9.67 2.333z\" stroke=\"currentColor\" stroke-width=\"1.4\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M18 10.5l-7 7M11 10.5l7 7\" stroke=\"currentColor\" stroke-width=\"1.4\" stroke-linecap=\"square\" stroke-linejoin=\"round\"/><defs><linearGradient id=\"paint0_linear_23750_95746\" x1=\"9.25\" y1=\"-5.25\" x2=\"12.166\" y2=\"32.083\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#FFF0F2\"/><stop offset=\"1\" stop-color=\"#DE1135\" stop-opacity=\".8\"/></linearGradient></defs>",
    "attrs": {
      "width": "29",
      "height": "28",
      "viewBox": "0 0 29 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});