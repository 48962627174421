define("ember-svg-jar/inlined/flat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3 20l32.071 5v21L2.333 39V20z\" fill=\"#BEC0C3\"/><path d=\"M63.5 18l-28.429 8v20l29.762-10.5V18z\" fill=\"#7D7D7D\"/><path d=\"M31.5 12l33.928 6-30.357 8-33.333-6L31.5 12z\" fill=\"url(#paint0_linear_4573_659)\"/><defs><linearGradient id=\"paint0_linear_4573_659\" x1=\"8.815\" y1=\"26\" x2=\"65.428\" y2=\"26\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#4E52B7\"/><stop offset=\"1\" stop-color=\"#2F85FF\"/></linearGradient></defs>",
    "attrs": {
      "width": "68",
      "height": "56",
      "viewBox": "0 0 68 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});