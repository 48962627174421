define("ember-svg-jar/inlined/thermal-break", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.802 10.467c.2.31.697.31.896 0l4.8-7.466c.2-.311-.05-.7-.448-.7h-9.6c-.398 0-.648.389-.448.7l4.8 7.466z\" fill=\"#FBFBFB\" stroke=\"#E9863E\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><g clip-path=\"url(#clip0_23855_52634)\"><path d=\"M6.402 6.76a.85.85 0 01.374-.704V4.403a.46.46 0 01.136-.338.463.463 0 01.339-.14c.135 0 .248.046.34.138a.464.464 0 01.138.338v1.654a.86.86 0 01.273.306.831.831 0 01.097.397.842.842 0 01-.848.848.846.846 0 01-.848-.847zm.294 0c0 .155.055.288.163.398.108.11.24.165.393.165a.545.545 0 00.4-.167.55.55 0 00-.132-.888l-.046-.023c-.017-.007-.025-.024-.025-.049V4.403a.179.179 0 00-.057-.135.202.202 0 00-.141-.053.2.2 0 00-.139.053.174.174 0 00-.056.135v1.79c0 .025-.009.042-.024.048l-.045.024a.53.53 0 00-.215.205.56.56 0 00-.076.29zm.13 0c0 .118.04.22.121.303a.41.41 0 00.592 0 .408.408 0 00.127-.302.406.406 0 00-.109-.278.418.418 0 00-.263-.142V5.115a.043.043 0 00-.017-.032.05.05 0 00-.036-.017.048.048 0 00-.035.014.05.05 0 00-.014.035v1.223a.422.422 0 00-.26.142.409.409 0 00-.106.28z\" fill=\"#E9863E\"/></g><defs><clipPath id=\"clip0_23855_52634\"><path fill=\"#fff\" transform=\"translate(4.75 3.5)\" d=\"M0 0h5v5H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "14",
      "height": "13",
      "viewBox": "0 0 14 13",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});