define("ember-svg-jar/inlined/aurora-checkbox-checked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_1_1471)\"><rect x=\"2\" y=\"2\" width=\"20\" height=\"20\" rx=\"3\" fill=\"#2D3648\"/><path d=\"M10.5 14.379l6.894-6.895 1.06 1.06L10.5 16.5l-4.773-4.773 1.06-1.06 3.713 3.712z\" fill=\"#fff\"/></g><defs><clipPath id=\"clip0_1_1471\"><rect x=\"2\" y=\"2\" width=\"20\" height=\"20\" rx=\"3\" fill=\"#fff\"/></clipPath></defs>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});