define("ember-svg-jar/inlined/navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g filter=\"url(#filter0_d_9122_1939)\"><path d=\"M2.5 9.167l15.833-7.5-7.5 15.833-1.666-6.666L2.5 9.167z\" stroke=\"#5448F4\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" shape-rendering=\"crispEdges\"/></g>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});