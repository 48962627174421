define("ember-paper/mixins/child-mixin", ["exports", "@ember/object/mixin", "@ember/object", "ember-paper/mixins/parent-mixin"], function (_exports, _mixin, _object, _parentMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module ember-paper
   */
  /**
   * @class ChildMixin
   * @extends Ember.Mixin
   */
  var _default = _exports.default = _mixin.default.create({
    // override to look for a specific parent class
    parentClass: _parentMixin.default,
    // this will typically be overriden when yielding a contextual component
    parentComponent: (0, _object.computed)({
      get: function get() {
        if (this._parentComponent !== undefined) {
          return this._parentComponent;
        }
        return this.nearestOfType(this.parentClass);
      },
      set: function set(key, value) {
        return this._parentComponent = value;
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
      if (this.parentComponent) {
        this.parentComponent.register(this);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (this.parentComponent) {
        this.parentComponent.unregister(this);
      }
    }
  });
});