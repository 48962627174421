define("ember-svg-jar/inlined/feedback-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M29.063 16.875v.938m-13.125-.938v.938m13.124 11.25s-1.875-3.75-6.562-3.75c-4.688 0-6.563 3.75-6.563 3.75M22.5 41.25c10.355 0 18.75-8.395 18.75-18.75S32.855 3.75 22.5 3.75 3.75 12.145 3.75 22.5s8.395 18.75 18.75 18.75z\" stroke=\"url(#paint0_linear_1900_22659)\" stroke-width=\"3\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M29.125 17v.938\" stroke=\"url(#paint1_linear_1900_22659)\" stroke-width=\"5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M29.125 17v.938\" stroke=\"url(#paint2_linear_1900_22659)\" stroke-width=\"5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M16 17v.938\" stroke=\"url(#paint3_linear_1900_22659)\" stroke-width=\"5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M16 17v.938\" stroke=\"url(#paint4_linear_1900_22659)\" stroke-width=\"5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><defs><linearGradient id=\"paint0_linear_1900_22659\" x1=\"7.917\" y1=\"41.25\" x2=\"41.25\" y2=\"41.25\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#4E52B7\"/><stop offset=\"1\" stop-color=\"#2F85FF\"/></linearGradient><linearGradient id=\"paint1_linear_1900_22659\" x1=\"29.236\" y1=\"17.938\" x2=\"30.125\" y2=\"17.938\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#4E52B7\"/><stop offset=\"1\" stop-color=\"#2F85FF\"/></linearGradient><linearGradient id=\"paint2_linear_1900_22659\" x1=\"29.236\" y1=\"17.938\" x2=\"30.125\" y2=\"17.938\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#4E52B7\"/></linearGradient><linearGradient id=\"paint3_linear_1900_22659\" x1=\"16.111\" y1=\"17.938\" x2=\"17\" y2=\"17.938\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#4E52B7\"/><stop offset=\"1\" stop-color=\"#2F85FF\"/></linearGradient><linearGradient id=\"paint4_linear_1900_22659\" x1=\"16.111\" y1=\"17.938\" x2=\"17\" y2=\"17.938\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#4E52B7\"/></linearGradient></defs>",
    "attrs": {
      "width": "45",
      "height": "45",
      "viewBox": "0 0 45 45",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});