define("ember-remodal/components/ember-remodal", ["exports", "@ember/service", "jquery", "@ember/object", "@ember/object/computed", "@ember/application", "@ember/object/evented", "rsvp", "@ember/runloop", "@ember/object/events", "@ember/component", "ember-remodal/templates/components/ember-remodal", "ember"], function (_exports, _service, _jquery, _object, _computed, _application, _evented, _rsvp, _runloop, _events, _component, _emberRemodal, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-on-calls-in-components */
  /* eslint-disable ember/closure-actions */
  var _default = _exports.default = _component.default.extend({
    layout: _emberRemodal.default,
    remodal: (0, _service.inject)(),
    attributeBindings: ['dataTestId:data-test-id'],
    classNames: ['remodal-component'],
    tagName: 'span',
    name: 'ember-remodal',
    modifier: '',
    modal: null,
    options: null,
    closeOnEscape: true,
    closeOnCancel: true,
    closeOnConfirm: true,
    hashTracking: false,
    closeOnOutsideClick: true,
    forService: false,
    disableForeground: false,
    disableAnimation: false,
    disableNativeClose: (0, _computed.reads)('disableForeground'),
    erOpenButton: false,
    erCancelButton: false,
    erConfirmButton: false,
    didInsertElement: function didInsertElement() {
      (0, _runloop.scheduleOnce)('afterRender', this, '_setProperties');
      (0, _runloop.scheduleOnce)('afterRender', this, '_registerObservers');
      (0, _runloop.scheduleOnce)('afterRender', this, '_checkForDeprecations');
      (0, _runloop.scheduleOnce)('afterRender', this, '_checkForTestingEnv');
    },
    willDestroyElement: function willDestroyElement() {
      (0, _runloop.scheduleOnce)('destroy', this, '_destroyDomElements');
      (0, _runloop.scheduleOnce)('destroy', this, '_deregisterObservers');
    },
    modalId: (0, _object.computed)('elementId', {
      get: function get() {
        return "[data-remodal-id=".concat(this.get('elementId'), "]");
      }
    }),
    animationState: (0, _object.computed)('disableAnimation', {
      get: function get() {
        if (this.get('disableAnimation')) {
          return 'disable-animation';
        } else {
          return '';
        }
      }
    }),
    openDidFire: (0, _evented.on)('opened', function () {
      this.sendAction('onOpen');
    }),
    closeDidFire: (0, _evented.on)('closed', function () {
      this.sendAction('onClose');
    }),
    open: function open() {
      return this._promiseAction('open');
    },
    close: function close() {
      var _this = this;
      if (this.get('modal')) {
        return this._promiseAction('close');
      } else {
        _ember.default.Logger.warn('ember-remodal: You called "close" on a modal that has not yet been opened. This is not a big deal, but I thought you should know. The returned promise will immediately resolve.', false, {
          id: 'ember-remodal.close-called-on-unitialized-modal'
        });
        return new _rsvp.Promise(function (resolve) {
          return resolve(_this);
        });
      }
    },
    _promiseAction: function _promiseAction(action) {
      var _this2 = this;
      var modal = this.get('modalId');
      var actionName = this._pastTense(action);
      this.send(action);
      return new _rsvp.Promise(function (resolve) {
        (0, _jquery.default)(document).one(actionName, modal, function () {
          return resolve(_this2);
        });
      });
    },
    _pastTense: function _pastTense(action) {
      if (action[action.length - 1] === 'e') {
        return "".concat(action, "d");
      } else {
        return "".concat(action, "ed");
      }
    },
    _setProperties: function _setProperties() {
      var opts = this.get('options');
      if (opts) {
        this.setProperties(opts);
      }
      if (this.get('forService')) {
        this.get('remodal').set(this.get('name'), this);
      }
    },
    _registerObservers: function _registerObservers() {
      var _this3 = this;
      var modal = this.get('modalId');
      (0, _jquery.default)(document).on('opened', modal, function () {
        return (0, _events.sendEvent)(_this3, 'opened');
      });
      (0, _jquery.default)(document).on('closed', modal, function () {
        return (0, _events.sendEvent)(_this3, 'closed');
      });
    },
    _deregisterObservers: function _deregisterObservers() {
      var modal = this.get('modalId');
      (0, _jquery.default)(document).off('opened', modal);
      (0, _jquery.default)(document).off('closed', modal);
    },
    _destroyDomElements: function _destroyDomElements() {
      var modal = this.get('modal');
      if (modal) {
        modal.destroy();
      }
    },
    _createInstanceAndOpen: function _createInstanceAndOpen() {
      var config = this._getConfig();
      var appendTo = config && config.APP.rootElement ? config.APP.rootElement : '.ember-application';
      var modal = (0, _jquery.default)(this.get('modalId')).remodal({
        appendTo: appendTo,
        hashTracking: this.get('hashTracking'),
        closeOnOutsideClick: this.get('closeOnOutsideClick'),
        closeOnEscape: this.get('closeOnEscape'),
        modifier: this.get('modifier')
      });
      this.set('modal', modal);
      this.send('open');
    },
    _checkForDeprecations: function _checkForDeprecations() {
      // Deprecations go here
    },
    _checkForTestingEnv: function _checkForTestingEnv() {
      var config = this._getConfig();
      if (config) {
        var env = config.environment;
        var remodalConfig = config['ember-remodal'];
        var disableAnimation;
        if (remodalConfig) {
          disableAnimation = remodalConfig.disableAnimationWhileTesting;
        }
        if (disableAnimation && env === 'test') {
          this.set('disableAnimation', true);
        }
      }
    },
    _getConfig: function _getConfig() {
      return (0, _application.getOwner)(this).resolveRegistration('config:environment');
    },
    _openModal: function _openModal() {
      this.get('modal').open();
    },
    _closeModal: function _closeModal() {
      this.get('modal').close();
    },
    _closeOnCondition: function _closeOnCondition(condition) {
      this.sendAction("on".concat(condition));
      if (this.get("closeOn".concat(condition))) {
        this.send('close');
      }
    },
    actions: {
      confirm: function confirm() {
        this._closeOnCondition('Confirm');
      },
      cancel: function cancel() {
        this._closeOnCondition('Cancel');
      },
      open: function open() {
        if (this.get('modal')) {
          (0, _runloop.scheduleOnce)('afterRender', this, '_openModal');
        } else {
          (0, _runloop.scheduleOnce)('afterRender', this, '_createInstanceAndOpen');
        }
      },
      close: function close() {
        (0, _runloop.next)(this, '_closeModal');
      }
    }
  });
});