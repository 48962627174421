define("ember-svg-jar/inlined/panel-with-rail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_23855_52641)\"><path fill=\"#ABBFDC\" d=\"M.75.5h12v16h-12z\"/><path fill=\"#000\" d=\"M.75 5.5h12v1h-12zM.75 11.5h12v1h-12z\"/></g><path stroke=\"#819FCB\" d=\"M1.25 1h11v15h-11z\"/><defs><clipPath id=\"clip0_23855_52641\"><path fill=\"#fff\" d=\"M.75.5h12v16h-12z\"/></clipPath></defs>",
    "attrs": {
      "width": "13",
      "height": "17",
      "viewBox": "0 0 13 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});