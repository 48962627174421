define("ember-svg-jar/inlined/arrow-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.707 8.293a1 1 0 00-1.414 0l-6 6a1 1 0 101.414 1.414L12 10.414l5.293 5.293a1 1 0 001.414-1.414l-6-6z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});