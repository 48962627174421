define("ember-svg-jar/inlined/link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_12396_83165)\" stroke=\"currentColor\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M8.333 10.833a4.168 4.168 0 006.284.45l2.5-2.5a4.167 4.167 0 00-5.892-5.892L9.792 4.316\"/><path d=\"M11.667 9.167a4.167 4.167 0 00-6.284-.45l-2.5 2.5a4.167 4.167 0 005.892 5.892l1.425-1.425\"/></g><defs><clipPath id=\"clip0_12396_83165\"><path fill=\"#fff\" d=\"M0 0h20v20H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});