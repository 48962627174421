define("ember-remodal/components/er-button", ["exports", "@ember/object", "@ember/component", "ember-remodal/templates/components/er-button"], function (_exports, _object, _component, _erButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/closure-actions */
  var _default = _exports.default = _component.default.extend({
    layout: _erButton.default,
    tagName: 'span',
    click: function click() {
      this.sendAction();
    },
    destination: (0, _object.computed)('modalId', {
      get: function get() {
        var modalId = this.get('modalId');
        if (modalId) {
          return "open-button-".concat(modalId);
        }
      }
    })
  });
});