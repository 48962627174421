define("ember-svg-jar/inlined/design", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.09 1.042l5.144 5.243 4.294-4.474a3.038 3.038 0 014.35-.058c.29.286.521.627.68 1.003a3.159 3.159 0 01-.622 3.41l-4.595 4.786 5.158 5.483c.691.735.664 1.9-.06 2.601l-3.054 2.955a1.795 1.795 0 01-2.564-.06l-4.958-5.272-3.595 3.744a.723.723 0 01-.334.198l-4.418 1.184a.718.718 0 01-.692-.184.743.743 0 01-.2-.697l1.05-4.51a.739.739 0 01.186-.345l3.898-4.06-5.23-5.332a1.858 1.858 0 01.006-2.601l2.991-3.02a1.795 1.795 0 012.565.006zm4.786 14.562l1.305 1.387 1.074-1.09.641.651-1.09 1.105 1.62 1.722 1.362-1.345.633.66-1.368 1.35.818.87a.36.36 0 00.513.012l3.053-2.955a.372.372 0 00.012-.52l-5.12-5.444-3.453 3.597zM5.062 2.08l5.16 5.26-3.451 3.594-1.444-1.472L6.43 8.401l-.625-.667L4.687 8.81 3.028 7.12l1.494-1.395-.614-.677-1.52 1.419-.832-.848a.372.372 0 01.002-.52l2.99-3.02a.36.36 0 01.514.001zm11.506.758a1.615 1.615 0 011.766-.38 1.624 1.624 0 01.892.88 1.669 1.669 0 01-.33 1.801L5.37 19.23l-3.055.819.726-3.12L16.568 2.84z\" fill=\"#717D96\"/>",
    "attrs": {
      "width": "21",
      "height": "21",
      "viewBox": "0 0 21 23",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});