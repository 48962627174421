define("ember-svg-jar/inlined/building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2.5 17.5h15M7.5 6.667h.833M7.5 10h.833M7.5 13.333h.833m3.334-6.666h.833M11.667 10h.833m-.833 3.333h.833M4.167 17.5V4.167A1.667 1.667 0 015.833 2.5h8.334a1.667 1.667 0 011.666 1.667V17.5\" stroke=\"#5448F4\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});