define("ember-paper/utils/invoke-action", ["exports", "@ember/debug", "@ember/object"], function (_exports, _debug, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.invokeAction = invokeAction;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function invokeAction(object, actionName) {
    (true && !(_typeof(object) === 'object') && (0, _debug.assert)('The first argument passed to invokeAction must be an object', _typeof(object) === 'object'));
    var action;
    if (typeof actionName === 'string') {
      action = (0, _object.get)(object, actionName);
    } else if (typeof actionName === 'function') {
      action = actionName;
    } else {
      (true && !(false) && (0, _debug.assert)('The second argument passed to invokeAction must be a string as actionName or a function', false));
    }
    for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      args[_key - 2] = arguments[_key];
    }
    if (typeof action === 'string') {
      object.sendAction.apply(object, [actionName].concat(args));
    } else if (typeof action === 'function') {
      return action.apply(void 0, args);
    }
  }
  ;
});