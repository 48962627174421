define("ember-svg-jar/inlined/heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.893 2.074a3.667 3.667 0 00-5.186 0L8 2.78l-.707-.706A3.668 3.668 0 002.107 7.26l.706.707L8 13.154l5.187-5.187.706-.707a3.667 3.667 0 000-5.186v0z\" stroke=\"#4A5468\" stroke-width=\"1.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "16",
      "height": "14",
      "viewBox": "0 0 16 14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});