define("ember-svg-jar/inlined/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M5.084 10.25h7.042\" stroke=\"#2D3648\" stroke-linecap=\"square\" stroke-linejoin=\"round\"/><path d=\"M5.084 10.25h7.042M5.084 10.25h7.042\" stroke=\"#000\" stroke-opacity=\".2\" stroke-linecap=\"square\" stroke-linejoin=\"round\"/><path d=\"M5.25 7h5\" stroke=\"#2D3648\" stroke-linecap=\"square\" stroke-linejoin=\"round\"/><path d=\"M5.25 7h5M5.25 7h5\" stroke=\"#000\" stroke-opacity=\".2\" stroke-linecap=\"square\" stroke-linejoin=\"round\"/><path d=\"M2.375 7h.005\" stroke=\"#2D3648\" stroke-width=\"2\" stroke-linecap=\"square\" stroke-linejoin=\"round\"/><path d=\"M2.375 7h.005M2.375 7h.005\" stroke=\"#000\" stroke-opacity=\".2\" stroke-width=\"2\" stroke-linecap=\"square\" stroke-linejoin=\"round\"/><path d=\"M5.084 3.75h7.042\" stroke=\"#2D3648\" stroke-linecap=\"square\" stroke-linejoin=\"round\"/><path d=\"M5.084 3.75h7.042M5.084 3.75h7.042\" stroke=\"#000\" stroke-opacity=\".2\" stroke-linecap=\"square\" stroke-linejoin=\"round\"/><path d=\"M2.375 3.75h.005\" stroke=\"#2D3648\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M2.375 3.75h.005M2.375 3.75h.005\" stroke=\"#000\" stroke-opacity=\".2\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M2.45 9l1.2 2h-2.4l1.2-2z\" fill=\"#2D3648\"/><path d=\"M2.45 9l1.2 2h-2.4l1.2-2z\" fill=\"#000\" fill-opacity=\".2\"/><path d=\"M2.45 9l1.2 2h-2.4l1.2-2z\" fill=\"#000\" fill-opacity=\".2\"/>",
    "attrs": {
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});