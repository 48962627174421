define("ember-svg-jar/inlined/feedback-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M23 41.25c10.355 0 18.75-8.395 18.75-18.75S33.355 3.75 23 3.75 4.25 12.145 4.25 22.5 12.645 41.25 23 41.25z\" stroke=\"url(#paint0_linear_1900_22688)\" stroke-width=\"3\" stroke-linejoin=\"round\"/><path d=\"M29.563 29.063s-1.875 3.75-6.563 3.75-6.563-3.75-6.563-3.75\" stroke=\"url(#paint1_linear_1900_22688)\" stroke-width=\"3\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M29.563 18.75v.938\" stroke=\"url(#paint2_linear_1900_22688)\" stroke-width=\"5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M16.438 18.75v.938\" stroke=\"url(#paint3_linear_1900_22688)\" stroke-width=\"5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><defs><linearGradient id=\"paint0_linear_1900_22688\" x1=\"8.417\" y1=\"41.25\" x2=\"41.75\" y2=\"41.25\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#4E52B7\"/><stop offset=\"1\" stop-color=\"#2F85FF\"/></linearGradient><linearGradient id=\"paint1_linear_1900_22688\" x1=\"17.896\" y1=\"32.813\" x2=\"29.563\" y2=\"32.813\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#4E52B7\"/><stop offset=\"1\" stop-color=\"#2F85FF\"/></linearGradient><linearGradient id=\"paint2_linear_1900_22688\" x1=\"29.674\" y1=\"19.688\" x2=\"30.563\" y2=\"19.688\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#4E52B7\"/></linearGradient><linearGradient id=\"paint3_linear_1900_22688\" x1=\"16.549\" y1=\"19.688\" x2=\"17.438\" y2=\"19.688\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#4E52B7\"/></linearGradient></defs>",
    "attrs": {
      "width": "46",
      "height": "45",
      "viewBox": "0 0 46 45",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});