define("ember-svg-jar/inlined/excel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.304.022a1.708 1.708 0 00-.397.032L2.31 1.574A2.257 2.257 0 00.5 3.778v12.442c0 1.067.762 1.997 1.809 2.206l7.598 1.52c.972.194 1.871-.501 2.03-1.447h4.813c1.51 0 2.75-1.24 2.75-2.75v-11C19.5 3.24 18.26 2 16.75 2H12v-.23c0-.953-.785-1.715-1.696-1.748zM10.2 1.525c.17-.034.299.071.299.245v16.46c0 .174-.13.28-.299.246v-.001l-7.597-1.52h-.001A.74.74 0 012 16.221V3.779c0-.362.248-.664.604-.735l7.597-1.52zM12 3.5h4.75c.7 0 1.25.551 1.25 1.25v11c0 .7-.55 1.25-1.25 1.25H12V3.5zm1.75 2a.75.75 0 100 1.5h2a.75.75 0 100-1.5h-2zM4.499 6.58a.75.75 0 00-.652 1.152l1.494 2.347-1.494 2.348a.75.75 0 001.265.806l1.117-1.756 1.118 1.755a.752.752 0 001.035.23.752.752 0 00.23-1.036L7.12 10.079l1.493-2.346a.75.75 0 10-1.266-.806L6.23 8.682 5.112 6.926a.748.748 0 00-.613-.347zM13.75 9.5a.75.75 0 100 1.5h2a.75.75 0 100-1.5h-2zm0 4a.751.751 0 100 1.5h2a.75.75 0 100-1.5h-2z\" fill=\"#3C4048\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});