define("ember-svg-jar/inlined/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_5052_31235)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.813.363C5.298.363 0 5.662 0 12.176c0 2.252.636 4.442 1.84 6.332a.561.561 0 10.948-.604 10.659 10.659 0 01-1.663-5.728c0-5.893 4.794-10.688 10.688-10.688 5.893 0 10.687 4.795 10.687 10.688 0 2.055-.585 4.051-1.691 5.773a.564.564 0 00.947.61 11.784 11.784 0 001.869-6.383c0-6.514-5.299-11.813-11.813-11.813zm0 5.625A4.318 4.318 0 007.5 10.301a4.318 4.318 0 004.313 4.312 4.318 4.318 0 004.312-4.312 4.318 4.318 0 00-4.313-4.313zm0 10.8c-2.86 0-5.721 1.089-7.898 3.266l-.019.021-.054.06a.563.563 0 00.04.795 11.782 11.782 0 007.93 3.058c2.94 0 5.757-1.085 7.932-3.06a.563.563 0 00.04-.795l-.03-.028a.571.571 0 00-.044-.05 11.131 11.131 0 00-7.898-3.267z\" fill=\"#2D3648\"/></g><defs><clipPath id=\"clip0_5052_31235\"><path fill=\"#fff\" d=\"M0 0h24v24H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});