define("ember-svg-jar/inlined/rosettte-success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11 1.833l2.408 1.756 2.98-.005.916 2.836 2.414 1.747L18.792 11l.926 2.832-2.414 1.747-.916 2.837-2.98-.006L11 20.166 8.592 18.41l-2.98.006-.915-2.837-2.415-1.747L3.21 11l-.927-2.833L4.697 6.42l.915-2.836 2.98.005L11 1.833z\" stroke=\"#5448F4\" stroke-width=\"1.6\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M7.792 11l2.292 2.291 4.583-4.583\" stroke=\"#5448F4\" stroke-width=\"1.6\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});