define("ember-svg-jar/inlined/team", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_5010_24666)\"><path d=\"M12 4.813a4.32 4.32 0 00-4.313 4.312c0 1.381.669 2.6 1.685 3.392a7.094 7.094 0 00-1.69.965c0-.016.005-.028.005-.044a2.888 2.888 0 00-2.875-2.876 2.888 2.888 0 00-2.875 2.876c0 .769.315 1.47.81 1.987C1.414 16.161.5 17.565.5 19.187h1.438a2.866 2.866 0 013.453-2.813 7.119 7.119 0 00-.579 2.814H6.25a5.74 5.74 0 015.75-5.75 5.74 5.74 0 015.75 5.75h1.438c0-.994-.208-1.943-.579-2.808.191-.04.382-.067.578-.067a2.866 2.866 0 012.875 2.875H23.5c0-1.623-.915-3.027-2.246-3.763a2.88 2.88 0 00.808-1.988 2.888 2.888 0 00-2.875-2.874 2.888 2.888 0 00-2.875 2.874c0 .017.006.029.006.045a7.094 7.094 0 00-1.69-.965c1.016-.792 1.684-2.01 1.684-3.392A4.32 4.32 0 0012 4.812zm0 1.437a2.866 2.866 0 012.875 2.875A2.866 2.866 0 0112 12a2.866 2.866 0 01-2.875-2.875A2.866 2.866 0 0112 6.25zM4.812 12c.803 0 1.438.634 1.438 1.438 0 .803-.635 1.437-1.438 1.437a1.426 1.426 0 01-1.437-1.438c0-.803.635-1.437 1.438-1.437zm14.375 0c.803 0 1.438.634 1.438 1.438 0 .803-.634 1.437-1.438 1.437a1.426 1.426 0 01-1.437-1.438c0-.803.634-1.437 1.438-1.437z\" fill=\"currentColor\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});