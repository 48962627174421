define("ember-paper/components/paper-toaster", ["exports", "@ember/service", "@ember/object/computed", "@ember/component", "ember-paper/templates/components/paper-toaster"], function (_exports, _service, _computed, _component, _paperToaster) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _paperToaster.default,
    tagName: '',
    paperToaster: (0, _service.inject)(),
    activeToast: (0, _computed.reads)('paperToaster.activeToast'),
    onClose: function onClose(toast) {
      this.paperToaster.cancelToast(toast);
    }
  });
});