define("ember-svg-jar/inlined/delete-left-stroke", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17.5 3.333H6.668L.834 10l5.833 6.666h10.834A1.667 1.667 0 0019.167 15V5a1.667 1.667 0 00-1.666-1.667v0z\" stroke=\"#E55D32\" stroke-width=\"1.6\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M15 7.5l-5 5\" stroke=\"#E55D32\" stroke-width=\"1.4\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M10 7.5l5 5\" stroke=\"#E55D32\" stroke-width=\"1.6\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});