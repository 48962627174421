define("ember-svg-jar/inlined/gable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3 37l16-17.5 14.5 23v12L3 48.5V37z\" fill=\"#BEC0C3\"/><path d=\"M63.5 29.96l-30 12.54v12l30-12V29.96z\" fill=\"#7D7D7D\"/><path d=\"M18.5 19l33-13.5 15.5 23-33.5 14-15-23.5z\" fill=\"url(#paint0_linear_4573_639)\"/><path d=\"M1 37.5L18.5 19v2L3 37.5H1z\" fill=\"url(#paint1_linear_4573_639)\"/><path d=\"M34.5 42l-16-23v2l15 21.5 1-.5z\" fill=\"url(#paint2_linear_4573_639)\"/><defs><linearGradient id=\"paint0_linear_4573_639\" x1=\"23.889\" y1=\"42.5\" x2=\"67\" y2=\"42.5\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#4E52B7\"/><stop offset=\"1\" stop-color=\"#2F85FF\"/></linearGradient><linearGradient id=\"paint1_linear_4573_639\" x1=\"-9.076\" y1=\"44.979\" x2=\"11.757\" y2=\"17.423\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#3237B8\"/><stop offset=\"1\" stop-color=\"#80B4FF\"/></linearGradient><linearGradient id=\"paint2_linear_4573_639\" x1=\"44.5\" y1=\"52\" x2=\"17\" y2=\"25\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#3237B8\"/><stop offset=\"1\" stop-color=\"#80B4FF\"/></linearGradient></defs>",
    "attrs": {
      "width": "68",
      "height": "56",
      "viewBox": "0 0 68 56",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});