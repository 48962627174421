define("ember-svg-jar/inlined/table-simple-exclude-48-regular", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2 5.125A3.125 3.125 0 015.125 2h9.5a3.125 3.125 0 013.125 3.125V10.5H10.5v7.25H5.125A3.125 3.125 0 012 14.625v-9.5zm14.5 0a1.875 1.875 0 00-1.875-1.875H10.5v6h6V5.125zM9.25 3.25H5.125A1.875 1.875 0 003.25 5.125V9.25h6v-6zm-6 11.375A1.875 1.875 0 005.125 16.5H9.25v-6h-6v4.125zm9.25 0a2.125 2.125 0 012.125-2.125h5.25A2.125 2.125 0 0122 14.625v5.25A2.125 2.125 0 0119.875 22h-5.25a2.125 2.125 0 01-2.125-2.125v-5.25z\" fill=\"#5448F4\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});