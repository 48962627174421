define("ember-svg-jar/inlined/warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9 6.5v1.666M9 11.5h.009m-5.782 3.333h11.546a1.667 1.667 0 001.444-2.5l-5.773-10a1.667 1.667 0 00-2.887 0l-5.773 10c-.642 1.111.16 2.5 1.443 2.5z\" stroke=\"#5448F4\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "18",
      "height": "16",
      "viewBox": "0 0 18 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});