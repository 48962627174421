define("ember-svg-jar/inlined/undo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.074 4.585A4.189 4.189 0 008.1 3.365l-3.997.018L6.02 1.448A.667.667 0 105.073.51l-3.05 3.078a.667.667 0 00.004.942l3.078 3.05a.667.667 0 10.939-.946L4.109 4.716l3.997-.018a2.86 2.86 0 012.882 2.856 2.86 2.86 0 01-2.856 2.882l-7.02.032a.666.666 0 10.005 1.333l7.02-.032a4.212 4.212 0 004.184-4.22 4.189 4.189 0 00-1.247-2.964z\" fill=\"#5448F4\"/>",
    "attrs": {
      "width": "13",
      "height": "12",
      "viewBox": "0 0 13 12",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});