define("ember-svg-jar/inlined/equipment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<mask id=\"a\" maskUnits=\"userSpaceOnUse\" x=\"5\" y=\"1\" width=\"12\" height=\"6\" fill=\"#000\"><path fill=\"currentColor\" d=\"M5 1h12v6H5z\"/><path d=\"M7 4a1 1 0 011-1h5.988a1 1 0 011 1v1H7V4z\"/></mask><path d=\"M14.988 5v1.6h1.6V5h-1.6zM7 5H5.4v1.6H7V5zm1-.4h5.988V1.4H8v3.2zm5.387-.6v1h3.2V4h-3.2zm1.6-.6H7v3.2h7.988V3.4zM8.6 5V4H5.4v1h3.2zm5.388-.4a.6.6 0 01-.6-.6h3.2a2.6 2.6 0 00-2.6-2.6v3.2zM8 1.4A2.6 2.6 0 005.4 4h3.2a.6.6 0 01-.6.6V1.4z\" fill=\"currentColor\" mask=\"url(#a)\"/><path d=\"M2.3 9.8h17.4V18a2.2 2.2 0 01-2.2 2.2h-13A2.2 2.2 0 012.3 18V9.8zM1.524 9.575h18.675V7.46a1.2 1.2 0 00-1.2-1.2H2.725a1.2 1.2 0 00-1.2 1.2v2.115z\" stroke=\"currentColor\" stroke-width=\"1.6\"/><path fill=\"currentColor\" d=\"M5 8h3v4H5z\"/><path d=\"M14.7 11.3V8.7h1.6v2.6h-1.6z\" fill=\"currentColor\" stroke=\"currentColor\" stroke-width=\"1.4\"/>",
    "attrs": {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});