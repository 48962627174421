define("ember-svg-jar/inlined/feedback-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M22.5 41.25c10.355 0 18.75-8.395 18.75-18.75S32.855 3.75 22.5 3.75 3.75 12.145 3.75 22.5s8.395 18.75 18.75 18.75z\" stroke=\"url(#paint0_linear_1900_22698)\" stroke-width=\"3\" stroke-linejoin=\"round\"/><path d=\"M22.5 32.813c4.688 0 6.563-3.75 6.563-3.75H15.938s1.874 3.75 6.562 3.75z\" stroke=\"url(#paint1_linear_1900_22698)\" stroke-width=\"4\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M29.063 18.75v.938\" stroke=\"url(#paint2_linear_1900_22698)\" stroke-width=\"5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M15.938 18.75v.938\" stroke=\"url(#paint3_linear_1900_22698)\" stroke-width=\"5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><defs><linearGradient id=\"paint0_linear_1900_22698\" x1=\"7.917\" y1=\"41.25\" x2=\"41.25\" y2=\"41.25\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#4E52B7\"/><stop offset=\"1\" stop-color=\"#2F85FF\"/></linearGradient><linearGradient id=\"paint1_linear_1900_22698\" x1=\"17.396\" y1=\"32.813\" x2=\"29.063\" y2=\"32.813\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#4E52B7\"/><stop offset=\"1\" stop-color=\"#2F85FF\"/></linearGradient><linearGradient id=\"paint2_linear_1900_22698\" x1=\"29.174\" y1=\"19.688\" x2=\"30.063\" y2=\"19.688\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#4E52B7\"/></linearGradient><linearGradient id=\"paint3_linear_1900_22698\" x1=\"16.049\" y1=\"19.688\" x2=\"16.938\" y2=\"19.688\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#4E52B7\"/></linearGradient></defs>",
    "attrs": {
      "width": "45",
      "height": "45",
      "viewBox": "0 0 45 45",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});