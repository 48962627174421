define("ember-svg-jar/inlined/hip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M22.305 19.5l26.95-8.5 14.237 12.5-27.966 12-13.22-16z\" fill=\"url(#paint0_linear_4573_631)\"/><path d=\"M22.305 19.5l13.22 16-30-5.5 16.78-10.5z\" fill=\"url(#paint1_linear_4573_631)\"/><path d=\"M63.492 23.5l-27.967 12v13L63.492 37V23.5z\" fill=\"#7D7D7D\"/><path d=\"M5.525 30l30 5.5v13l-30-5V30z\" fill=\"#BEC0C4\"/><defs><linearGradient id=\"paint0_linear_4573_631\" x1=\"26.881\" y1=\"35.5\" x2=\"63.492\" y2=\"35.5\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#3237B8\"/><stop offset=\"1\" stop-color=\"#2F85FF\"/></linearGradient><linearGradient id=\"paint1_linear_4573_631\" x1=\"12.644\" y1=\"17.5\" x2=\"35.224\" y2=\"35.87\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#575CD0\"/><stop offset=\"1\" stop-color=\"#69A7FF\"/></linearGradient></defs>",
    "attrs": {
      "width": "68",
      "height": "56",
      "viewBox": "0 0 68 56",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});