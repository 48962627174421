define("ember-svg-jar/inlined/padlock-open", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.778 8H3.222C2.547 8 2 8.651 2 9.455v5.09C2 15.35 2.547 16 3.222 16h8.556c.675 0 1.222-.651 1.222-1.454V9.454C13 8.65 12.453 8 11.778 8zM4 3.5C4.5 2 6.56 1.417 7.5 1.417a3.542 3.542 0 013.542 3.542v2.833\" stroke=\"#A0ABC0\" stroke-width=\"1.6\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "15",
      "height": "17",
      "viewBox": "0 0 15 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});