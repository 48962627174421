define("ember-svg-jar/inlined/address-pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 22s7.5-6 7.5-12.5a7.5 7.5 0 00-15 0C4.5 16 12 22 12 22z\" fill=\"#5448F4\" stroke=\"#5448F4\" stroke-width=\"2\" stroke-linejoin=\"round\"/><path d=\"M12 12.5a3 3 0 100-6 3 3 0 000 6z\" fill=\"#FAFAFA\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});