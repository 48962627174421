define("ember-svg-jar/inlined/phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_13281_62674)\"><path d=\"M14.667 11.28v2a1.332 1.332 0 01-1.454 1.333 13.193 13.193 0 01-5.753-2.047 13 13 0 01-4-4 13.193 13.193 0 01-2.047-5.78A1.333 1.333 0 012.74 1.333h2A1.333 1.333 0 016.073 2.48a8.56 8.56 0 00.467 1.873 1.333 1.333 0 01-.3 1.407l-.847.846a10.667 10.667 0 004 4l.847-.846a1.334 1.334 0 011.407-.3 8.561 8.561 0 001.873.466 1.334 1.334 0 011.147 1.354z\" stroke=\"currentColor\" stroke-width=\"1.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></g><defs><clipPath id=\"clip0_13281_62674\"><path fill=\"#fff\" d=\"M0 0h16v16H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});