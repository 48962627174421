define("ember-paper/components/paper-reset-button", ["exports", "@ember/component", "ember-paper/templates/components/paper-reset-button"], function (_exports, _component, _paperResetButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    tagName: '',
    layout: _paperResetButton.default,
    handleClick: function handleClick(onReset, e) {
      if (onReset) {
        onReset(e);
      }
    }
  });
});