define("ember-svg-jar/inlined/visa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M31.113 0c-3.337 0-6.32 1.818-6.32 5.175 0 3.85 5.29 4.116 5.29 6.051 0 .815-.888 1.544-2.406 1.544-2.154 0-3.763-1.019-3.763-1.019l-.689 3.388S25.08 16 27.541 16c3.648 0 6.52-1.906 6.52-5.322 0-4.069-5.312-4.327-5.312-6.122 0-.638.73-1.337 2.243-1.337 1.707 0 3.1.74 3.1.74l.674-3.271S33.25 0 31.113 0zM.581.247L.5.741s1.404.27 2.669.809c1.628.617 1.744.977 2.018 2.093l2.988 12.102h4.005L18.351.247h-3.996L10.39 10.784 8.772 1.852C8.624.83 7.872.247 6.952.247H.581zm19.377 0l-3.135 15.498h3.811L23.758.247h-3.8zm21.254 0c-.919 0-1.406.517-1.763 1.42l-5.583 14.078h3.996l.773-2.346h4.869l.47 2.346H47.5L44.424.247h-3.212zm.52 4.187l1.185 5.816h-3.174l1.99-5.816h-.001z\" fill=\"#1434CB\"/>",
    "attrs": {
      "width": "48",
      "height": "16",
      "viewBox": "0 0 48 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});