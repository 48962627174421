define("ember-svg-jar/inlined/minus-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.5 13.334a5.833 5.833 0 100-11.667 5.833 5.833 0 000 11.667zM5.167 7.5h4.666\" stroke=\"#FAFAFA\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "15",
      "height": "15",
      "viewBox": "0 0 15 15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});