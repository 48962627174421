define("ember-paper/components/paper-sidenav-inner", ["exports", "@ember/service", "@ember/component", "ember-paper/templates/components/paper-sidenav-inner", "@ember/object", "@ember/runloop", "ember-paper/utils/invoke-action"], function (_exports, _service, _component, _paperSidenavInner, _object, _runloop, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module ember-paper
   */
  /* globals FastBoot */
  /**
   * @class PaperSidenavInner
   * @extends Ember.Component
   * @uses TransitionMixin
   */
  var _default = _exports.default = _component.default.extend({
    tagName: '',
    layout: _paperSidenavInner.default,
    constants: (0, _service.inject)(),
    paperSidenav: (0, _service.inject)(),
    name: 'default',
    position: 'left',
    lockedOpen: 'gt-sm',
    closed: true,
    closeOnClick: true,
    tabindex: -1,
    positionClass: (0, _object.computed)('position', function () {
      return "md-sidenav-".concat(this.position);
    }),
    init: function init() {
      // need to updateLockedOpen() first because otherwise the transition classes
      // would be applied due to transition mixin's `init`
      if (typeof FastBoot === 'undefined') {
        this.updateLockedOpen();
      }
      this._super.apply(this, arguments);
      this.paperSidenav.register(this.name, this);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._updateOnResize = _runloop.run.bind(this, this.updateLockedOpen);
      window.addEventListener('resize', this._updateOnResize);
      this.updateLockedOpen();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (typeof FastBoot === 'undefined') {
        this.updateLockedOpen();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      window.removeEventListener('resize', this._updateOnResize);
      this.paperSidenav.unregister(this.name, this);
      this._updateOnResize = null;
    },
    updateLockedOpen: function updateLockedOpen() {
      var lockedOpen = this.lockedOpen;
      var isLockedOpen;

      // if `true` or `false` is specified, always/never "lock open"
      // otherwise proceed with normal matchMedia test
      if (typeof lockedOpen === 'boolean') {
        isLockedOpen = lockedOpen;
      } else {
        var mediaQuery = this.constants.MEDIA[lockedOpen] || lockedOpen;
        isLockedOpen = window.matchMedia(mediaQuery).matches;
      }
      var coercedIsLockedOpen = !!this.isLockedOpen;
      if (coercedIsLockedOpen !== isLockedOpen) {
        this.set('isLockedOpen', isLockedOpen);

        // if sidenav is open and we enter lockedOpen,
        // make the sidenav enter the "closed" state
        if (!this.closed && isLockedOpen) {
          (0, _invokeAction.invokeAction)(this, 'onToggle', false);
        }
      }
    },
    handleClick: function handleClick(closeOnClick, isLockedOpen, onToggle) {
      if (onToggle && closeOnClick && !isLockedOpen) {
        onToggle(false);
      }
    },
    open: function open() {
      if (this.closed && this.isLockedOpen) {
        (0, _invokeAction.invokeAction)(this, 'onToggle', true);
      }
    },
    close: function close() {
      if (!this.closed && !this.isLockedOpen) {
        (0, _invokeAction.invokeAction)(this, 'onToggle', false);
      }
    },
    toggle: function toggle() {
      if (!this.isLockedOpen) {
        (0, _invokeAction.invokeAction)(this, 'onToggle', this.closed);
      }
    }
  });
});