define("ember-svg-jar/inlined/crop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17 23v-4H7c-.55 0-1.021-.196-1.413-.588A1.922 1.922 0 015 17V7H1V5h4V1h2v16h16v2h-4v4h-2zm0-8V7H9V5h8c.55 0 1.021.196 1.413.588.392.392.588.863.587 1.412v8h-2z\" fill=\"#FAFAFA\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});