define("ember-svg-jar/inlined/checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#F8F8F9\" d=\"M38.8 30.915c0 3.117 2.552 5.668 5.669 5.668h21.938c3.117 0 5.669-2.551 5.669-5.668V8.977c0-3.118-2.552-5.669-5.669-5.669H44.471c-3.119 0-5.67 2.551-5.67 5.669L38.8 30.915z\"/><path fill=\"none\" stroke=\"#CFD1D2\" stroke-width=\"3\" stroke-miterlimit=\"10\" d=\"M38.8 30.915c0 3.117 2.552 5.668 5.669 5.668h21.938c3.117 0 5.669-2.551 5.669-5.668V8.977c0-3.118-2.552-5.669-5.669-5.669H44.471c-3.119 0-5.67 2.551-5.67 5.669L38.8 30.915h0z\"/><g><path fill=\"#F8F8F9\" d=\"M38.8 29.385c0 3.117 2.552 5.668 5.669 5.668h21.938c3.117 0 5.669-2.551 5.669-5.668V7.448c0-3.118-2.552-5.669-5.669-5.669H44.471c-3.119 0-5.67 2.551-5.67 5.669L38.8 29.385z\"/><path fill=\"none\" stroke=\"#B4B4B5\" stroke-width=\"3\" stroke-miterlimit=\"10\" d=\"M38.8 29.385c0 3.117 2.552 5.668 5.669 5.668h21.938c3.117 0 5.669-2.551 5.669-5.668V7.448c0-3.118-2.552-5.669-5.669-5.669H44.471c-3.119 0-5.67 2.551-5.67 5.669L38.8 29.385h0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "36.333",
      "height": "39.167",
      "viewBox": "37.167 0 36.333 39.167"
    }
  };
});