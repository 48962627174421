define("ember-paper/components/paper-dialog-container", ["exports", "@ember/component", "ember-paper/utils/invoke-action"], function (_exports, _component, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module ember-paper
   */
  /**
   * @class PaperDialogContainer
   * @extends Ember.Component
   */
  var _default = _exports.default = _component.default.extend({
    classNames: ['md-dialog-container'],
    mouseDown: function mouseDown(ev) {
      this._sourceEl = ev.target;
    },
    mouseUp: function mouseUp(ev) {
      if (this._sourceEl === this.element && ev.target === this.element) {
        ev.stopPropagation();
        ev.preventDefault();
        (0, _invokeAction.invokeAction)(this, 'outsideClicked');
      }
    }
  });
});