define("ember-paper/components/paper-speed-dial-trigger", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    tagName: 'md-fab-trigger',
    click: function click() {
      this.speedDial.toggle();
    },
    focusOut: function focusOut() {
      this.speedDial.close();
    }
  });
});