define("ember-svg-jar/inlined/skip-rail-clamp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path stroke=\"#000\" d=\"M1.25 4h11v6h-11z\"/>",
    "attrs": {
      "width": "13",
      "height": "13",
      "viewBox": "0 0 13 13",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});