define("ember-svg-jar/inlined/data-needed-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M0 0h11.917v2.6H0V0zm15.167 0H26v2.6H15.167V0zm3.25 7.8H26v2.6h-7.583V7.8zm-10.834 0h7.584v2.6H7.583V7.8zM0 7.8h4.333v2.6H0V7.8zm0 7.8h26v2.6H0v-2.6zm0 7.8h18.417V26H0v-2.6zm21.667 0H26V26h-4.333v-2.6z\" fill=\"url(#paint0_linear_7267_39692)\"/><defs><linearGradient id=\"paint0_linear_7267_39692\" x1=\"2.889\" y1=\"26\" x2=\"26\" y2=\"26\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#4E52B7\"/><stop offset=\"1\" stop-color=\"#2F85FF\"/></linearGradient></defs>",
    "attrs": {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});