define("ember-svg-jar/inlined/roof-snow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#4A5468\"><path d=\"M10.137 1.38a.366.366 0 01.242-.376h.088a.364.364 0 01.419.376v1.216l.859-.862a.366.366 0 01.264-.11.36.36 0 01.36.226.363.363 0 01-.096.414l-.859.862h1.212a.375.375 0 010 .752h-1.212l.86.862a.376.376 0 010 .53.373.373 0 01-.53 0l-.858-.862v1.216A.375.375 0 0110.51 6a.375.375 0 01-.374-.376V4.408l-.86.863a.373.373 0 01-.528 0 .376.376 0 010-.531l.86-.862H8.396a.376.376 0 01-.044-.752H9.608l-.859-.862a.365.365 0 01.22-.641.366.366 0 01.309.11l.859.863V1.38zM15.137 4.38a.366.366 0 01.242-.376h.088a.364.364 0 01.419.376v1.216l.859-.862a.366.366 0 01.264-.11.36.36 0 01.36.226.363.363 0 01-.096.414l-.859.862h1.212a.375.375 0 010 .752h-1.212l.86.862a.376.376 0 010 .53.373.373 0 01-.53 0l-.858-.862v1.216A.375.375 0 0115.51 9a.375.375 0 01-.374-.376V7.408l-.86.863a.373.373 0 01-.528 0 .376.376 0 010-.531l.86-.862h-1.212a.376.376 0 01-.044-.752H14.608l-.859-.862a.365.365 0 01.22-.641.366.366 0 01.309.11l.859.863V4.38zM4.137 4.38a.366.366 0 01.242-.376h.088a.364.364 0 01.419.376v1.216l.859-.862a.365.365 0 01.264-.11.36.36 0 01.36.226.363.363 0 01-.096.414l-.859.862h1.212a.375.375 0 010 .752H5.414l.86.862a.376.376 0 010 .53.373.373 0 01-.53 0l-.858-.862v1.216A.375.375 0 014.51 9a.375.375 0 01-.374-.376V7.408l-.86.863a.373.373 0 01-.528 0 .376.376 0 010-.531l.86-.862H2.396a.376.376 0 01-.044-.752H3.608l-.859-.862a.365.365 0 01.22-.641.366.366 0 01.309.11l.859.863V4.38z\"/></g><path d=\"M9.995 8.002l-6.99 3.662a.01.01 0 00-.005.009v7.317c0 .006.004.01.01.01h13.98a.01.01 0 00.01-.01v-7.317a.01.01 0 00-.005-.01l-6.99-3.66a.01.01 0 00-.01 0z\" stroke=\"#4A5468\" stroke-width=\"1.5\" stroke-linecap=\"round\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});