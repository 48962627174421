define("ember-svg-jar/inlined/upload-cloud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M26.667 26.667L20 20l-6.667 6.667\" stroke=\"url(#paint0_linear_16951_106062)\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M20 20v15\" stroke=\"url(#paint1_linear_16951_106062)\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M33.983 30.65A8.334 8.334 0 0030 15h-2.1A13.331 13.331 0 008.735 6.563 13.334 13.334 0 005 27.167\" stroke=\"url(#paint2_linear_16951_106062)\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M26.667 26.667L20 20l-6.667 6.667\" stroke=\"url(#paint3_linear_16951_106062)\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><defs><linearGradient id=\"paint0_linear_16951_106062\" x1=\"14.815\" y1=\"26.667\" x2=\"26.667\" y2=\"26.667\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#4E52B7\"/><stop offset=\"1\" stop-color=\"#2F85FF\"/></linearGradient><linearGradient id=\"paint1_linear_16951_106062\" x1=\"20.111\" y1=\"35\" x2=\"21\" y2=\"35\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#4E52B7\"/><stop offset=\"1\" stop-color=\"#2F85FF\"/></linearGradient><linearGradient id=\"paint2_linear_16951_106062\" x1=\"5.732\" y1=\"30.65\" x2=\"38.328\" y2=\"30.65\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#4E52B7\"/><stop offset=\"1\" stop-color=\"#2F85FF\"/></linearGradient><linearGradient id=\"paint3_linear_16951_106062\" x1=\"14.815\" y1=\"26.667\" x2=\"26.667\" y2=\"26.667\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#4E52B7\"/><stop offset=\"1\" stop-color=\"#2F85FF\"/></linearGradient></defs>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});