define("ember-svg-jar/inlined/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.542 18.708a7.667 7.667 0 100-15.333 7.667 7.667 0 000 15.333zM20.126 20.625l-4.169-4.169\" stroke=\"#5448F4\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "23",
      "height": "24",
      "viewBox": "0 0 23 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});