define("ember-svg-jar/inlined/tornado", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 9.75c4.556 0 8.25-1.343 8.25-3s-3.694-3-8.25-3-8.25 1.343-8.25 3 3.694 3 8.25 3z\" stroke=\"#4A5468\" stroke-width=\"1.5\" stroke-miterlimit=\"10\"/><path d=\"M20.25 11.25c-.714.987-3.569 1.5-6.75 1.5-3.727 0-6.75-1.008-6.75-2.25 0-.372.27-.723.75-1.032\" stroke=\"#4A5468\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linecap=\"round\"/><path d=\"M18.75 14.25c-.61.953-2.834 1.5-5.25 1.5-2.9 0-5.25-1.008-5.25-2.25 0-.424.274-.82.75-1.16M15.75 17.25c0 .828-1.342 1.5-3 1.5s-3-.672-3-1.5 1.342-1.5 3-1.5\" stroke=\"#4A5468\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linecap=\"round\"/><path d=\"M11.25 20.25c.828 0 1.5-.336 1.5-.75s-.672-.75-1.5-.75-1.5.336-1.5.75.672.75 1.5.75z\" stroke=\"#4A5468\" stroke-width=\"1.5\" stroke-miterlimit=\"10\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});