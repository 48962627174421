define("ember-svg-jar/inlined/success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11 1.832l2.407 1.756 2.98-.005.916 2.836 2.414 1.747L18.791 11l.926 2.832-2.414 1.748-.916 2.836-2.98-.006-2.408 1.756-2.407-1.756-2.98.006-.916-2.836L2.28 13.83 3.208 11 2.28 8.166 4.696 6.42l.915-2.836 2.98.005L11 1.832z\" stroke=\"currentColor\" stroke-width=\"1.6\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M7.793 10.999l2.292 2.291 4.583-4.583\" stroke=\"currentColor\" stroke-width=\"1.6\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});