define("ember-svg-jar/inlined/move", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M5.667 14.667a.708.708 0 100-1.417.708.708 0 000 1.417zM5.667 9.708a.708.708 0 100-1.417.708.708 0 000 1.417zM5.667 4.75a.708.708 0 100-1.416.708.708 0 000 1.417zM11.333 4.75a.708.708 0 100-1.416.708.708 0 000 1.417zM11.333 9.708a.708.708 0 100-1.417.708.708 0 000 1.417zM11.333 14.667a.708.708 0 100-1.417.708.708 0 000 1.417z\" fill=\"currentColor\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "17",
      "height": "18",
      "viewBox": "0 0 17 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});