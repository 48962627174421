define("ember-remodal/templates/components/er-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "YlkiIriQ",
    "block": "[[[41,[33,1],[[[6,[39,2],null,[[\"to\"],[[33,1]]],[[\"default\"],[[[[1,\"    \"],[18,1,null],[1,\"\\n\"]],[]]]]]],[]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"destination\",\"ember-wormhole\",\"yield\"]]",
    "moduleName": "ember-remodal/templates/components/er-button.hbs",
    "isStrictMode": false
  });
});