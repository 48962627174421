define("ember-paper/mixins/translate3d-mixin", ["exports", "@ember/object/mixin", "@ember/string", "@ember/object", "@ember/runloop", "ember-css-transitions/utils/transition-utils", "@ember/application"], function (_exports, _mixin, _string, _object, _runloop, _transitionUtils, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module ember-paper
   */
  /**
   * @class Translate3dMixin
   * @extends Ember.Mixin
   */
  var _default = _exports.default = _mixin.default.create({
    attributeBindings: ['translateStyle:style'],
    classNameBindings: ['transformIn:md-transition-in'],
    fromStyle: (0, _object.computed)('defaultedOpenFrom', function () {
      return this.toTransformCss(this.calculateZoomToOrigin(this.element, this.defaultedOpenFrom));
    }),
    centerStyle: (0, _object.computed)(function () {
      return this.toTransformCss('');
    }),
    translateStyle: (0, _object.computed)('fromStyle', 'centerStyle', 'transformStyleApply', function () {
      if (this.transformStyleApply === 'from') {
        return (0, _string.htmlSafe)(this.fromStyle);
      } else if (this.transformStyleApply === 'main') {
        return (0, _string.htmlSafe)(this.centerStyle);
      } else {
        return (0, _string.htmlSafe)('');
      }
    }),
    onTranslateFromEnd: function onTranslateFromEnd() {},
    onTranslateToEnd: function onTranslateToEnd() {},
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      _runloop.run.schedule('afterRender', function () {
        // Set translate3d style to start at the `from` origin
        _this.set('transformStyleApply', 'from');
        // Wait while CSS takes affect
        // Set the `main` styles and run the transition-in styles
        (0, _transitionUtils.nextTick)().then(function () {
          if (_this.isDestroyed) {
            return;
          }
          _runloop.run.later(function () {
            if (!_this.isDestroying && !_this.isDestroyed) {
              _this.onTranslateFromEnd();
            }
          }, (0, _transitionUtils.computeTimeout)(_this.element) || 0);
          if (!_this.isDestroying && !_this.isDestroyed) {
            _this.set('transformStyleApply', 'main');
            _this.set('transformIn', true);
          }
        });
      });
    },
    /**
     * Specific reversal of the request translate animation above...
     *
     * @protected
     */
    willDestroyElement: function willDestroyElement() {
      var _this2 = this;
      this._super.apply(this, arguments);
      var config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      var containerClone = this.element.parentNode.cloneNode(true);
      var dialogClone = containerClone.querySelector('md-dialog');
      var parent = this.defaultedParent;
      if (config.environment === 'test' && !this.parent) {
        parent = '#ember-testing';
      }
      var parentNode = document.querySelector(parent);
      if (parentNode && parentNode.parentNode !== null) {
        parentNode.parentNode.appendChild(containerClone);
      }
      var toStyle = this.toTransformCss(this.calculateZoomToOrigin(this.element, this.defaultedCloseTo));
      var origin = typeof this.origin === 'string' ? document.querySelector(this.origin) : this.origin;
      (0, _transitionUtils.nextTick)().then(function () {
        dialogClone.classList.remove('md-transition-in');
        dialogClone.classList.add('md-transition-out');
        dialogClone.style.cssText = toStyle;
        (0, _transitionUtils.nextTick)().then(function () {
          _runloop.run.later(function () {
            if (containerClone.parentNode !== null) {
              containerClone.parentNode.removeChild(containerClone);
            }
            _this2.onTranslateToEnd(origin);
          }, (0, _transitionUtils.computeTimeout)(dialogClone) || 0);
        });
      });
    },
    /**
     * Calculate the zoom transform from dialog to origin.
     *
     * We use this to set the dialog position immediately;
     * then the md-transition-in actually translates back to
     * `translate3d(0,0,0) scale(1.0)`...
     *
     * NOTE: all values are rounded to the nearest integer
     *
     * @public
     */
    calculateZoomToOrigin: function calculateZoomToOrigin(element, originator) {
      var zoomStyle;
      originator = typeof originator === 'string' ? document.querySelector(originator) : originator;
      if (originator) {
        var originBnds = this.copyRect(originator.getBoundingClientRect());
        var dialogRect = this.copyRect(element.getBoundingClientRect());
        var dialogCenterPt = this.centerPointFor(dialogRect);
        var originCenterPt = this.centerPointFor(originBnds);
        zoomStyle = {
          centerX: originCenterPt.x - dialogCenterPt.x,
          centerY: originCenterPt.y - dialogCenterPt.y,
          scaleX: Math.min(0.5, originBnds.width / dialogRect.width),
          scaleY: Math.min(0.5, originBnds.height / dialogRect.height)
        };
      } else {
        zoomStyle = {
          centerX: 0,
          centerY: 0,
          scaleX: 0.5,
          scaleY: 0.5
        };
      }
      return "translate3d(".concat(zoomStyle.centerX, "px, ").concat(zoomStyle.centerY, "px, 0 ) scale(").concat(zoomStyle.scaleX, ", ").concat(zoomStyle.scaleY, ")");
    },
    /**
     * Convert the translate CSS value to key/value pair(s).
     *
     * @public
     */
    toTransformCss: function toTransformCss(transform) {
      return "transform: ".concat(transform, ";");
    },
    /**
     * Clone the Rect and calculate the height/width if needed
     *
     * @public
     */
    copyRect: function copyRect(source, destination) {
      if (!source) {
        return null;
      }
      destination = destination || {};
      'left top right bottom width height'.split(' ').forEach(function (key) {
        destination[key] = Math.round(source[key]);
      });
      destination.width = destination.width || destination.right - destination.left;
      destination.height = destination.height || destination.bottom - destination.top;
      return destination;
    },
    /**
     * Calculate 'rounded' center point of Rect
     *
     * @public
     */
    centerPointFor: function centerPointFor(targetRect) {
      return {
        x: Math.round(targetRect.left + targetRect.width / 2),
        y: Math.round(targetRect.top + targetRect.height / 2)
      };
    }
  });
});