define("ember-svg-jar/inlined/plus-white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.5 1.5a1 1 0 10-2 0v6h-6a1 1 0 000 2h6v6a1 1 0 102 0v-6h6a1 1 0 100-2h-6v-6z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "17",
      "height": "17",
      "viewBox": "0 0 17 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});