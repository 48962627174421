define("ember-svg-jar/inlined/clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_22164_44012)\" stroke=\"#0050C2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M8 14.667A6.667 6.667 0 108 1.334a6.667 6.667 0 000 13.333z\"/><path d=\"M8 4v4l2.667 1.333\"/></g><defs><clipPath id=\"clip0_22164_44012\"><path fill=\"#fff\" d=\"M0 0h16v16H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});