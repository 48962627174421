define("ember-svg-jar/inlined/snow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.308 0c-2.113 0-3.866 1.192-4.933 2.852-.202-.028-.39-.086-.606-.086-2.311 0-4.154 1.599-4.76 3.717C1.797 6.562 0 8.373 0 10.603c0 2.28 1.871 4.149 4.154 4.149h14.77c2.794 0 5.076-2.28 5.076-5.071 0-2.374-1.68-4.3-3.894-4.84C19.554 2.1 17.206 0 14.308 0zm0 1.844a4.124 4.124 0 014.125 3.803l.086.778.75.058a3.208 3.208 0 012.885 3.198 3.214 3.214 0 01-3.23 3.227H4.153a2.295 2.295 0 01-2.308-2.305A2.295 2.295 0 014.5 8.327l.98.172.087-.98A3.21 3.21 0 018.77 4.61c.242 0 .49.026.75.087l.721.173.318-.663a4.143 4.143 0 013.75-2.362zm3.404 13.685a.487.487 0 00-.347.364.482.482 0 00.174.472l1.124 1.124H17.02a.491.491 0 00.058.98h1.586l-1.125 1.123a.489.489 0 10.693.691l1.125-1.123v1.584a.49.49 0 00.98 0V19.16l1.126 1.123a.49.49 0 00.692-.691l-1.125-1.124h1.586a.49.49 0 000-.98H21.03l1.125-1.123a.471.471 0 00.126-.54.471.471 0 00-.472-.296.48.48 0 00-.346.145l-1.125 1.123v-.663a.495.495 0 00-.16-.374.478.478 0 00-.389-.115h-.057a.478.478 0 00-.375.49v.662l-1.125-1.123a.48.48 0 00-.404-.145h-.115zm-12.808.116a.477.477 0 00-.317.49v1.584l-1.125-1.124a.481.481 0 00-.404-.144H3a.472.472 0 00-.382.346.47.47 0 00.151.49l1.125 1.124H2.25a.491.491 0 00.058.98h1.586L2.77 20.513a.489.489 0 10.693.691l1.125-1.123v1.584a.49.49 0 00.98 0v-1.584l1.125 1.123a.49.49 0 00.693-.691L6.26 19.39h1.586a.49.49 0 000-.98H6.26l1.125-1.123a.472.472 0 00.126-.54.471.471 0 00-.473-.296.48.48 0 00-.346.144L5.567 17.72v-1.585a.495.495 0 00-.158-.374.478.478 0 00-.39-.115h-.115zm7.385 1.844a.477.477 0 00-.318.49v1.584l-1.125-1.124a.48.48 0 00-.404-.144.478.478 0 00-.288.836l1.125 1.123H9.635a.491.491 0 00.057.98h1.587l-1.125 1.124a.489.489 0 10.692.691l1.125-1.123v1.584a.49.49 0 00.98 0v-1.584l1.126 1.123a.49.49 0 00.692-.691l-1.125-1.124h1.587a.49.49 0 000-.98h-1.587l1.125-1.123a.472.472 0 00.126-.54.471.471 0 00-.472-.296.48.48 0 00-.346.144l-1.125 1.124v-1.585a.496.496 0 00-.159-.374.478.478 0 00-.39-.115h-.114z\" fill=\"#4A5468\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});