define("ember-paper/components/paper-radio-group", ["exports", "@ember/service", "@ember/object/computed", "@ember/component", "@ember/debug", "ember-paper/templates/components/paper-radio-group", "ember-paper/mixins/focusable-mixin", "ember-composability-tools", "@ember/utils", "ember-paper/utils/invoke-action"], function (_exports, _service, _computed, _component, _debug, _paperRadioGroup, _focusableMixin, _emberComposabilityTools, _utils, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module ember-paper
   */
  /**
   * @class PaperRadioGroup
   * @extends Ember.Component
   * @uses FocusableMixin
   * @uses ParentMixin
   */
  var _default = _exports.default = _component.default.extend(_focusableMixin.default, _emberComposabilityTools.ParentMixin, {
    layout: _paperRadioGroup.default,
    tagName: 'md-radio-group',
    tabindex: 0,
    /* FocusableMixin Overrides */
    focusOnlyOnKey: true,
    radioComponent: 'paper-radio',
    labelComponent: 'paper-radio-group-label',
    role: 'radiogroup',
    constants: (0, _service.inject)(),
    // Lifecycle hooks
    init: function init() {
      this._super.apply(this, arguments);
      (true && !(this.onChange !== undefined) && (0, _debug.assert)('{{paper-radio-group}} requires an `onChange` action or null for no action', this.onChange !== undefined));
    },
    attributeBindings: ['role', 'ariaLabelledby:aria-labelledby'],
    enabledChildRadios: (0, _computed.filterBy)('childComponents', 'disabled', false),
    childValues: (0, _computed.mapBy)('enabledChildRadios', 'value'),
    hasLabel: (0, _computed.notEmpty)('labelNode'),
    keyDown: function keyDown(ev) {
      switch (ev.which) {
        case this.get('constants.KEYCODE.LEFT_ARROW'):
        case this.get('constants.KEYCODE.UP_ARROW'):
          ev.preventDefault();
          this.select(-1);
          break;
        case this.get('constants.KEYCODE.RIGHT_ARROW'):
        case this.get('constants.KEYCODE.DOWN_ARROW'):
          ev.preventDefault();
          this.select(1);
          break;
      }
    },
    select: function select(increment) {
      var groupValue = this.groupValue;
      var index = 0;
      if ((0, _utils.isPresent)(groupValue)) {
        index = this.childValues.indexOf(groupValue);
        index += increment;
        var length = this.get('childValues.length');
        index = (index % length + length) % length;
      }
      var childRadio = this.enabledChildRadios.objectAt(index);
      childRadio.set('focused', true);
      (0, _invokeAction.invokeAction)(this, 'onChange', childRadio.get('value'));
    },
    actions: {
      onChange: function onChange(value) {
        (0, _invokeAction.invokeAction)(this, 'onChange', value);
      }
    }
  });
});