define("ember-paper/components/paper-item", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "ember-paper/templates/components/paper-item", "ember-composability-tools", "ember-paper/utils/invoke-action"], function (_exports, _computed, _component, _object, _paperItem, _emberComposabilityTools, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module ember-paper
   */
  /**
   * @class PaperItem
   * @extends Ember.Component
   * @uses ParentMixin
   */
  var _default = _exports.default = _component.default.extend(_emberComposabilityTools.ParentMixin, {
    layout: _paperItem.default,
    tagName: 'md-list-item',
    _mouseEnterHandler: undefined,
    _mouseLeaveHandler: undefined,
    // Ripple Overrides
    // disable ripple when we have a primary action or when we don't have a proxied component
    noink: (0, _object.computed)('hasPrimaryAction', 'hasProxiedComponent', function () {
      return this.hasPrimaryAction || !this.hasProxiedComponent;
    }),
    classNameBindings: ['hasProxiedComponent:md-proxy-focus', 'shouldBeClickable:md-clickable', 'focused:md-focused', 'hasPrimaryAction:_md-button-wrap'],
    attributeBindings: ['role', 'tabindex', 'title'],
    role: 'listitem',
    tabindex: '-1',
    proxiedComponents: (0, _computed.filter)('childComponents', function (c) {
      return !c.get('skipProxy');
    }),
    hasProxiedComponent: (0, _computed.bool)('proxiedComponents.length'),
    shouldBeClickable: (0, _computed.or)('hasProxiedComponent', 'onClick'),
    hasPrimaryAction: (0, _computed.or)('onClick', 'href'),
    noProxy: (0, _object.computed)('hasPrimaryAction', 'hasProxiedComponent', function () {
      return !this.hasPrimaryAction && !this.hasProxiedComponent;
    }),
    secondaryItem: (0, _object.computed)('proxiedComponents.[]', function () {
      var proxiedComponents = this.proxiedComponents;
      return proxiedComponents.objectAt(0);
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._mouseEnterHandler = this.handleMouseEnter.bind(this);
      this._mouseLeaveHandler = this.handleMouseLeave.bind(this);
      this.element.addEventListener('mouseenter', this._mouseEnterHandler);
      this.element.addEventListener('mouseleave', this._mouseLeaveHandler);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.element.removeEventListener('mouseenter', this._mouseEnterHandler);
      this.element.removeEventListener('mouseleave', this._mouseLeaveHandler);
      this._mouseEnterHandler = undefined;
      this._mouseLeaveHandler = undefined;
    },
    click: function click() {
      var _this = this;
      this.proxiedComponents.forEach(function (component) {
        if (component.processProxy && !component.get('disabled') && component.get('bubbles') | !_this.hasPrimaryAction) {
          component.processProxy();
        }
      });
    },
    handleMouseEnter: function handleMouseEnter(e) {
      (0, _invokeAction.invokeAction)(this, 'onMouseEnter', e);
    },
    handleMouseLeave: function handleMouseLeave(e) {
      (0, _invokeAction.invokeAction)(this, 'onMouseLeave', e);
    }
  });
});