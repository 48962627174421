define("ember-svg-jar/inlined/hashtag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.333 1l-3.8 12.666M13.666 1l-3.8 12.666M16.2 4.8H2.267m12.666 5.066H1\" stroke=\"#5448F4\" stroke-width=\"1.5\" stroke-linecap=\"square\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "17",
      "height": "15",
      "viewBox": "0 0 17 15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});