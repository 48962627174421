define("ember-svg-jar/inlined/pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M35 64.166s21.875-17.5 21.875-36.458c0-12.081-9.794-21.875-21.875-21.875s-21.875 9.794-21.875 21.875C13.125 46.666 35 64.166 35 64.166z\" fill=\"url(#paint0_linear_1337_8770)\"/><path d=\"M35 36.458a8.75 8.75 0 100-17.5 8.75 8.75 0 000 17.5z\" fill=\"#F9F9F9\"/><defs><linearGradient id=\"paint0_linear_1337_8770\" x1=\"10\" y1=\"107.592\" x2=\"72.272\" y2=\"103.921\" gradientUnits=\"userSpaceOnUse\"><stop/></linearGradient></defs>",
    "attrs": {
      "width": "70",
      "height": "70",
      "viewBox": "0 0 70 70",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});