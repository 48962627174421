define("ember-svg-jar/inlined/trash2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2 4h12M5.334 4V2.668a1.333 1.333 0 011.333-1.333h2.667a1.333 1.333 0 011.333 1.333v1.334m2 0v9.333a1.334 1.334 0 01-1.333 1.333H4.667a1.334 1.334 0 01-1.333-1.333V4.001h9.333z\" stroke=\"#4A5468\" stroke-width=\"1.4\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});