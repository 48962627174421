define("ember-svg-jar/inlined/person-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10 7.5a2.917 2.917 0 100-5.833A2.917 2.917 0 0010 7.5zM1.667 17.084c0-3.682 3.358-6.667 7.5-6.667m3.75 7.083l4.167-4.166-1.667-1.667-4.167 4.167V17.5h1.667z\" stroke=\"#5448F4\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});