define("ember-paper/mixins/parent-mixin", ["exports", "@ember/object/mixin", "@ember/object", "@ember/array"], function (_exports, _mixin, _object, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module ember-paper
   */
  /**
   * @class ParentMixin
   * @extends Ember.Mixin
   */
  var _default = _exports.default = _mixin.default.create({
    childComponents: (0, _object.computed)(function () {
      return (0, _array.A)();
    }),
    register: function register(child) {
      this.childComponents.pushObject(child);
    },
    unregister: function unregister(child) {
      this.childComponents.removeObject(child);
    }
  });
});