define("ember-svg-jar/inlined/documents-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect x=\".8\" y=\".8\" width=\"24.4\" height=\"24.4\" rx=\"3.2\" stroke=\"url(#paint0_linear_7267_39708)\" stroke-width=\"1.6\"/><path d=\"M5.605 6.703h5.84c.403 0 .73-.275.73-.615 0-.34-.327-.614-.73-.614h-5.84c-.403 0-.73.275-.73.614 0 .34.327.615.73.615z\" fill=\"url(#paint1_linear_7267_39708)\"/><path d=\"M17.285 7.933H5.605c-.403 0-.73.275-.73.614 0 .34.327.615.73.615h11.68c.403 0 .73-.275.73-.615 0-.34-.327-.614-.73-.614z\" fill=\"url(#paint2_linear_7267_39708)\"/><path d=\"M17.285 10.391H5.605c-.403 0-.73.276-.73.615 0 .34.327.615.73.615h11.68c.403 0 .73-.275.73-.615 0-.34-.327-.615-.73-.615z\" fill=\"url(#paint3_linear_7267_39708)\"/><defs><linearGradient id=\"paint0_linear_7267_39708\" x1=\"2.889\" y1=\"26\" x2=\"26\" y2=\"26\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#4E52B7\"/><stop offset=\"1\" stop-color=\"#2F85FF\"/></linearGradient><linearGradient id=\"paint1_linear_7267_39708\" x1=\"5.686\" y1=\"6.703\" x2=\"12.175\" y2=\"6.703\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#4E52B7\"/><stop offset=\"1\" stop-color=\"#2F85FF\"/></linearGradient><linearGradient id=\"paint2_linear_7267_39708\" x1=\"6.335\" y1=\"9.162\" x2=\"18.015\" y2=\"9.162\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#4E52B7\"/><stop offset=\"1\" stop-color=\"#2F85FF\"/></linearGradient><linearGradient id=\"paint3_linear_7267_39708\" x1=\"6.335\" y1=\"11.621\" x2=\"18.015\" y2=\"11.621\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#4E52B7\"/><stop offset=\"1\" stop-color=\"#2F85FF\"/></linearGradient></defs>",
    "attrs": {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});