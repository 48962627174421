define("ember-svg-jar/inlined/map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.023.001a2.245 2.245 0 00-.547.049l-4.29.92A1.508 1.508 0 000 2.436v11.77a1.122 1.122 0 001.36 1.1l4.614-.988 4.71 1.345a2.258 2.258 0 001.09.037l4.29-.92a1.507 1.507 0 001.186-1.466V1.543a1.122 1.122 0 00-1.36-1.1l-4.614.989L6.566.086a2.253 2.253 0 00-.543-.085zM5.25 1.632V12.94l-3.449.739a.247.247 0 01-.301-.243V2.637c0-.118.082-.219.196-.244l3.554-.76zm1.5.067l3.75 1.07v11.282L6.75 12.98V1.699zm8.699.373a.249.249 0 01.301.243v10.797a.248.248 0 01-.196.243L12 14.117V2.811l3.449-.74z\" fill=\"#5448F4\"/>",
    "attrs": {
      "width": "18",
      "height": "16",
      "viewBox": "0 0 18 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});