define("ember-svg-jar/inlined/monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3 20h18\" stroke=\"url(#paint0_linear_26181_74787)\" stroke-width=\"2\" stroke-linecap=\"square\" stroke-linejoin=\"round\"/><path d=\"M20 3H4a2 2 0 00-2 2v10a2 2 0 002 2h16a2 2 0 002-2V5a2 2 0 00-2-2z\" stroke=\"url(#paint1_linear_26181_74787)\" stroke-width=\"2\" stroke-linecap=\"square\" stroke-linejoin=\"round\"/><defs><linearGradient id=\"paint0_linear_26181_74787\" x1=\"21.621\" y1=\"20.5\" x2=\"14.767\" y2=\"28.404\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#4E52B7\"/><stop offset=\"1\" stop-color=\"#100699\"/></linearGradient><linearGradient id=\"paint1_linear_26181_74787\" x1=\"22.69\" y1=\"10\" x2=\"5.093\" y2=\"11.611\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#4E52B7\"/><stop offset=\"1\" stop-color=\"#100699\"/></linearGradient></defs>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none"
    }
  };
});