define("ember-svg-jar/inlined/alert-triangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.505 4.503L2.623 21a2.333 2.333 0 001.995 3.5h19.764a2.333 2.333 0 001.995-3.5L16.495 4.503a2.333 2.333 0 00-3.99 0z\" fill=\"#fff\"/><path d=\"M12.505 4.503L2.623 21a2.333 2.333 0 001.995 3.5h19.764a2.333 2.333 0 001.995-3.5L16.495 4.503a2.333 2.333 0 00-3.99 0z\" fill=\"url(#paint0_linear_23758_95768)\" fill-opacity=\".3\"/><path d=\"M12.505 4.503L2.623 21a2.333 2.333 0 001.995 3.5h19.764a2.333 2.333 0 001.995-3.5L16.495 4.503a2.333 2.333 0 00-3.99 0v0z\" stroke=\"currentColor\" stroke-width=\"1.6\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M14.5 10.5v4.667\" stroke=\"currentColor\" stroke-width=\"1.6\" stroke-linecap=\"square\" stroke-linejoin=\"round\"/><path d=\"M14.5 19.833h.012\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><defs><linearGradient id=\"paint0_linear_23758_95768\" x1=\"10.234\" y1=\"3.38\" x2=\"9.776\" y2=\"24.503\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#FFF5EA\"/><stop offset=\"1\" stop-color=\"#CE6F00\" stop-opacity=\".6\"/></linearGradient></defs>",
    "attrs": {
      "width": "29",
      "height": "28",
      "viewBox": "0 0 29 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});