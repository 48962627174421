define("ember-svg-jar/inlined/dots-vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"8\" cy=\"2\" r=\"1.5\" fill=\"#495773\"/><circle cx=\"8\" cy=\"7\" r=\"1.5\" fill=\"#495773\"/><circle cx=\"8\" cy=\"12\" r=\"1.5\" fill=\"#495773\"/>",
    "attrs": {
      "width": "16",
      "height": "14",
      "viewBox": "0 0 16 14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});