define("ember-svg-jar/inlined/windsock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10 4.5l3 .5v5l-3 .5v-6zM16 5l3 .5v5l-3 .5V5z\" fill=\"#4A5468\"/><path d=\"M3.75 3v18\" stroke=\"#4A5468\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linecap=\"round\"/><path d=\"M21.75 9.75l-15 1.5v-7.5l15 1.5v4.5z\" stroke=\"#4A5468\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M9.75 4.125v6.75M12.75 4.5v6M15.75 4.688v5.25M18.75 5.063V9.75\" stroke=\"#4A5468\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});