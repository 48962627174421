define("ember-svg-jar/inlined/rotate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.12 11.236a5.417 5.417 0 01-6.44-.9L1.083 8.862v1.971a.542.542 0 11-1.083 0v-3.25c0-.299.243-.541.542-.541h3.25a.542.542 0 010 1.083h-1.91l1.54 1.422.016.015a4.334 4.334 0 10-1.026-4.507.542.542 0 01-1.022-.36 5.417 5.417 0 117.73 6.54z\" fill=\"#4A5468\"/>",
    "attrs": {
      "width": "13",
      "height": "13",
      "viewBox": "0 0 13 13",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});