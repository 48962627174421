define("ember-svg-jar/inlined/calculator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.452.083c.76 0 1.381.641 1.381 1.422v8.532c0 .78-.622 1.421-1.38 1.421H2.005c-.759 0-1.381-.64-1.381-1.421V1.505c0-.78.622-1.422 1.381-1.422h6.446zm0 .948H2.006a.461.461 0 00-.46.474v8.532c0 .266.201.474.46.474h6.446c.26 0 .46-.208.46-.474V1.505a.461.461 0 00-.46-.474zm-.46.948v.948H2.467V1.98h5.525zm0 2.844v.948H7.07v-.948h.92zm-1.842 0v.948h-.92v-.948h.92zm-1.842 0v.948H2.467v-.948h1.841zM7.992 6.72v.948H7.07v-.948h.92zm-1.842 0v.948h-.92v-.948h.92zm-1.842 0v.948H2.467v-.948h1.841zm3.684 1.896v.948H7.07v-.948h.92zm-1.842 0v.948h-.92v-.948h.92zm-1.842 0v.948H2.467v-.948h1.841z\" fill=\"#495773\"/>",
    "attrs": {
      "width": "10",
      "height": "12",
      "viewBox": "0 0 10 12",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});