define("ember-svg-jar/inlined/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.5 13h5.625M10.313 2.687a1.326 1.326 0 111.874 1.875l-7.812 7.813-2.5.625.625-2.5 7.813-7.813z\" stroke=\"#fff\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "15",
      "height": "16",
      "viewBox": "0 0 15 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});