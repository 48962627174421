define("ember-paper/components/paper-radio-group-label", ["exports", "@ember/component", "ember-paper/templates/components/paper-radio-group-label"], function (_exports, _component, _paperRadioGroupLabel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _paperRadioGroupLabel.default,
    tagName: 'md-label',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.setAriaLabelledby) {
        this.setAriaLabelledby(this.elementId);
      }
    }
  });
});