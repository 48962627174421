define("ember-paper/mixins/validation-mixin", ["exports", "@ember/object/mixin", "@ember/debug", "@ember/array", "@ember/object", "@ember/object/computed", "@ember/string", "@ember/utils", "ember-paper/validators/required", "ember-paper/validators/min", "ember-paper/validators/max", "ember-paper/validators/minlength", "ember-paper/validators/maxlength", "ember-paper/utils/invoke-action"], function (_exports, _mixin, _debug, _array, _object, _computed, _string, _utils, _required, _min, _max, _minlength, _maxlength, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; } /**
 * @module ember-paper
 */
  /**
   * In order to make validation generic it is required that components using the validation mixin
   * specify what property the validation logic should be based on.
   *
   * @public
   *
   * @return computed property that depends on the supplied property name
   */
  function buildComputedValidationMessages(property) {
    var validations = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    var customValidations = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    var validationParams = validations.map(function (v) {
      return (0, _object.get)(v, 'param');
    }).filter(function (v) {
      return !(0, _utils.isBlank)(v);
    });
    var customValidationParams = customValidations.map(function (v) {
      return (0, _object.get)(v, 'param');
    }).filter(function (v) {
      return !(0, _utils.isBlank)(v);
    });
    return _object.computed.apply(void 0, [property, 'errors.[]', 'customValidations.[]'].concat(_toConsumableArray(validationParams), _toConsumableArray(customValidationParams), [function () {
      var _this = this;
      var validations = (0, _array.A)();
      var messages = (0, _array.A)();

      // built-in validations
      validations.pushObjects(this.validations());

      // custom validations
      var customValidations = this.customValidations;
      (true && !((0, _array.isArray)(customValidations)) && (0, _debug.assert)('`customValidations` must be an array', (0, _array.isArray)(customValidations)));
      validations.pushObjects(customValidations);

      // execute validations
      var currentValue = this.get(property);
      validations.forEach(function (validation) {
        (true && !(validation && validation.validate && typeof validation.validate === 'function') && (0, _debug.assert)('validation must include a `validate(value)` function', validation && validation.validate && typeof validation.validate === 'function'));
        try {
          var valParam = (0, _object.get)(validation, 'param');
          var paramValue = valParam ? _this.get(valParam) : undefined;
          if (!validation.validate(currentValue, paramValue)) {
            var message = _this.get("errorMessages.".concat(valParam)) || (0, _object.get)(validation, 'message');
            messages.pushObject({
              message: (0, _string.loc)(message.string || message, paramValue, currentValue)
            });
          }
        } catch (error) {
          (true && (0, _debug.warn)("Exception with validation: ".concat(validation, " ").concat(error), false));
        }
      });

      // error messages array
      var errors = this.errors || [];
      (true && !((0, _array.isArray)(errors)) && (0, _debug.assert)('`errors` must be an array', (0, _array.isArray)(errors)));
      messages.pushObjects(errors.map(function (e) {
        return (0, _object.get)(e, 'message') ? e : {
          message: e
        };
      }));
      return messages;
    }]));
  }

  /**
   * @class ValidationMixin
   * @extends Ember.Mixin
   */
  var _default = _exports.default = _mixin.default.create({
    validationErrorMessages: null,
    lastIsInvalid: undefined,
    validationProperty: null,
    // property that validation should be based on
    init: function init() {
      this._super.apply(this, arguments);
      (true && !(this.validationProperty) && (0, _debug.assert)('validationProperty must be set', this.validationProperty));
      if (!this.validationErrorMessages) {
        var computedValidationMessages = buildComputedValidationMessages(this.validationProperty, this.validations(), this.customValidations);
        (0, _object.defineProperty)(this, 'validationErrorMessages', computedValidationMessages);
      }
    },
    hasErrorMessages: (0, _computed.bool)('validationErrorMessages.length'),
    /**
     * The result of isInvalid is appropriate for controlling the display of
     * validation error messages. It also may be used to distinguish whether
     * the input would be considered valid after it is touched.
     *
     * @public
     *
     * @return {boolean} Whether the input is or would be invalid.
     *    false: input is valid (touched or not), or is no longer rendered
     *    true: input has been touched and is invalid.
     */
    isInvalid: (0, _computed.reads)('hasErrorMessages'),
    isValid: (0, _computed.not)('isInvalid'),
    /**
     * Return the built-in validations.
     *
     * May be overridden to provide additional built-in validations. Be sure to
     * call this._super() to retrieve the standard validations.
     *
     * @public
     */
    validations: function validations() {
      return [_required.default, _min.default, _max.default, _minlength.default, _maxlength.default];
    },
    notifyValidityChange: function notifyValidityChange() {
      var isValid = this.isValid;
      var lastIsValid = this.lastIsValid;
      var isTouched = this.isTouched;
      var lastIsTouched = this.lastIsTouched;
      if (lastIsValid !== isValid || lastIsTouched !== isTouched) {
        (0, _invokeAction.invokeAction)(this, 'onValidityChange', isValid);
        this.set('lastIsValid', isValid);
        this.set('lastIsTouched', isTouched);
      }
    },
    customValidations: [],
    errors: []
  });
});